export const styles = theme => {
  const ICON_SIZE = theme.spacing(6.25);
  return {
    container: {
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'left',
      margin: 'auto',
      paddingBottom: `${theme.spacing(3)}px`,
      paddingTop: 0,
      width: `calc(100vw - ${theme.spacing(8)}px)`
    },
    contentCtr: {
      ...theme.squareplan.window.width,
      margin: 'auto',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      flexWrap: 'wrap',
      paddingBottom: `${theme.spacing(5)}px`,
      '& div': {
        margin: `${theme.spacing(2)}px 0`,
        flexBasis: '33.33%'
      },
      '& div p': {
        fontSize: '1.5rem',
        fontWeight: 600,
        textAlign: 'center',
        margin: 'unset'
      },
      [theme.breakpoints.down('md')]: {
        justifyContent: 'center'
      }
    },
    iconBox: {
      display: 'flex',
      flexDirection: 'row',
      padding: `0 ${theme.spacing(1.25)}px`,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& img': {
        width: ICON_SIZE,
        height: ICON_SIZE,
        marginBottom: theme.spacing(2)
      },
      [theme.breakpoints.down('xs')]: {
        width: theme.spacing(37.5)
      }
    },
    featureText: {
      margin: 'auto',
      marginLeft: theme.spacing(1),
      fontSize: theme.spacing(2.1)
    },
    title: {
      textAlign: 'center',
      margin: `${theme.spacing(5)}px 0`,
      fontWeight: 800
    },
    subtitle: {
      margin: 'auto',
      textAlign: 'center',
      ...theme.squareplan.window.width
    },
    imgCtr: {
      ...theme.squareplan.window.width,
      margin: 'auto',
      display: 'block',
      marginBottom: theme.spacing(8)
    }
  };
};
