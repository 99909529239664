export const styles = theme => ({
  container: {
    paddingTop: theme.spacing(9),
    paddingBottom: theme.spacing(11)
  },
  contentCtr: {
    ...theme.squareplan.window.width,
    margin: '0 auto',
    '&.left': {
      textAlign: 'left'
    },
    '&.center': {
      textAlign: 'center'
    }
  },
  topContentCtr: {
    marginBottom: theme.spacing(6)
  },
  LinksSectionCtr: {
    display: 'flex',
    alignContent: 'space-around',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap'
  },
  toolAnchorCtr: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignContent: 'space-between',
    height: '100%',
    justifyContent: 'space-between',
    overflow: 'hidden'
  },
  toolCtr: {
    width: '30%',
    height: 200,
    display: 'inline-block',
    margin: theme.spacing(1),
    borderRadius: theme.palette.border.radius,
    border: `1px solid ${theme.palette.primary.main}`,
    padding: `${theme.spacing(2.5)}px ${theme.spacing(2.5)}px`,
    fontSize: '1rem',
    fontWeight: 700,
    '&:hover': {
      backgroundColor: 'hsl(0,0%,95%)',
      textDecoration: 'none'
    },
    [theme.breakpoints.up('lg')]: {
      width: `calc(25% - ${theme.spacing(2)}px)`,
      height: 200
    },
    [theme.breakpoints.down('sm')]: {
      width: `calc(50% - ${theme.spacing(2)}px)`,
      height: 150
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: 150
    }
  },
  toolImg: {
    height: '50%',
    marginBottom: theme.spacing(1)
  },
  toolCaption: {
    marginBottom: theme.spacing(1.5),
    lineHeight: '1.25rem'
  }
});
