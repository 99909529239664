// @flow
/* eslint-disable react/no-multi-comp */
import React from 'react';
import { graphql } from 'gatsby';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { window, document } from 'browser-monads';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { Typography } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MailIcon from '@material-ui/icons/Mail';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import { makeStyles } from '@material-ui/core/styles';
import SPButton from './SPButton';
import { styles } from './ContentfulSidebar.Style';
import theme from '../theme';

import type { spButton } from '../utils/types';

type Props = {
  sidebar: Object // JSON
};

const ContentfulSidebar = (props: Props) => {
  console.log('[ContentfulSidebar] props: ', props);
  const { sidebar } = props;
  // inject styles
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const renderOptions = {
    renderNode: {
      // eslint-disable-next-line react/display-name
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Typography className={classes.subTitle} variant='body1'>
          {children}
        </Typography>
      )
    }
  };

  function onScroll() {
    let links = document.querySelectorAll('#drawer a');
    let active_links = document.querySelectorAll('#drawer a.active');
    links.forEach((item, i) => {
      let refElement = item.getAttribute('href').substring(1);
      let scrollPos = document
        .getElementById(refElement)
        .getBoundingClientRect().top;
      if (
        item.getBoundingClientRect().top >= scrollPos &&
        item.getBoundingClientRect().top - scrollPos <
          sidebar[i].sectionSize * 76 + 48
      ) {
        active_links.forEach((el, i) => {
          el.classList.remove('active');
        });
        item.classList.add('active');
      } else {
        item.classList.remove('active');
      }
    });
    let drawer = document.querySelectorAll('#drawer div')[0];
    let bodyHeight = document.querySelectorAll('body')[0].offsetHeight;
    bodyHeight - window.scrollY < 1000
      ? drawer.setAttribute('style', 'top:125px')
      : drawer.setAttribute('style', 'top:350px');
  }

  window.addEventListener('scroll', function (e) {
    onScroll();
  });

  return (
    <Grid
      classes={{
        root: classes.container
      }}
      item
      xs={12}
    >
      <Drawer
        ModalProps={{
          keepMounted: true // Better open performance on mobile.
        }}
        anchor='left'
        classes={{
          paper: window.scrollY < 6380 ? classes.drawer : classes.drawerHide
        }}
        id='drawer'
        variant='permanent'
      >
        <List id='drawerList'>
          {sidebar.map((item, index) => (
            <ListItem
              button
              className={classes.listItemCtr}
              key={`link-${index}`}
            >
              <AnchorLink href={`#${item.anchorId}`}>{item.label}</AnchorLink>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </Grid>
  );
};

export default ContentfulSidebar;

export const query = graphql`
  fragment ContentfulSidebar on ContentfulSidebar {
    sidebar: sidebarItems {
      label
      anchorId
      sectionSize
    }
  }
`;
