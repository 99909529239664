// @flow
import React from 'react';
import classNames from 'classnames';
import queryString from 'query-string';

import { styles } from './ContentfulLink.Style';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(styles);
const ContentfulLink = props => {
  const classes = useStyles();
  const {
    url,
    iconPosition,
    label,
    icon,
    children,
    ctaFunctions,
    setMsgToSend
  } = props;

  let linkUrl = null;
  try {
    linkUrl = new URL(url);
    console.log('[SPButton] linkUrl: ', linkUrl);
  } catch (e) {
    console.log('not a valid url: ', url);
  }

  const handleNeoFuncClick = e => {
    e.preventDefault();
    e.stopPropagation();
    const parsed = queryString.parse(linkUrl.search);
    const funcName =
      linkUrl.hostname !== ''
        ? linkUrl.hostname
        : linkUrl.pathname.substring(2);
    console.log('[ContentfulLink] ======== funcName: ', funcName);
    console.log('[ContentfulLink] ======== parsed: ', parsed);
    if (
      typeof ctaFunctions === 'object' &&
      typeof ctaFunctions[funcName] === 'function'
    ) {
      eval(`ctaFunctions.${funcName}(${true})`);
    }

    if (
      typeof setMsgToSend !== 'undefined' &&
      parsed.msgToSend !== '' &&
      typeof parsed.msgToSend !== 'undefined' &&
      parsed.msgToSend !== null
    ) {
      setMsgToSend(parsed.msgToSend);
    }
  };

  console.log('[ContentfulLink] children: ', children);
  console.log('[ContentfulLink] props: ', props);
  return (
    <React.Fragment>
      {
        // MOBILE + NEO MAGIC LINK. HARDCODE TO TEXTING US
        // TODO: update contentful link data model to include default message
        linkUrl &&
        linkUrl.protocol === 'neo:' &&
        typeof navigator !== 'undefined' &&
        /Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        ) ? (
          <a
            className={classNames(classes ? classes.container : null)}
            onClick={
              linkUrl && linkUrl.protocol === 'neo:' ? handleNeoFuncClick : null
            }
          >
            {children}
          </a>
        ) : null
      }
      {
        // DESKTOP + NEO MAGIC LINK.
        linkUrl &&
        linkUrl.protocol === 'neo:' &&
        typeof navigator !== 'undefined' &&
        !/Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        ) ? (
          <a
            className={classNames(classes ? classes.container : null)}
            onClick={
              linkUrl && linkUrl.protocol === 'neo:' ? handleNeoFuncClick : null
            }
          >
            {children}
          </a>
        ) : null
      }
      {
        // ! NEO MAGIC LINK.
        !linkUrl || (linkUrl && linkUrl.protocol !== 'neo:') ? (
          <a
            className={classNames(classes ? classes.container : null)}
            href={url}
          >
            {children}
          </a>
        ) : null
      }
    </React.Fragment>
  );
};

export default ContentfulLink;
