// @flow
import React from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import ReactMarkdown from 'react-markdown';
import Button from '@material-ui/core/Button';

import { makeStyles } from '@material-ui/core/styles';
import { styles } from './SeoHeading1Text.Style';
import { Typography } from '@material-ui/core';

type Props = {
  text: string
};

const useStyles = makeStyles(styles);
const SeoHeading1Text = (props: Props) => {
  const classes = useStyles();
  console.log('[SeoHeading1Text] props: ', props);
  const { text } = props;

  const renderers = {
    paragraph: ({ children }) => {
      console.log('[SeoHeading1Text][paragraph] children: ', children);
      return (
        <Typography component='h1' variant='body1'>
          {children}
        </Typography>
      );
    },
    heading: ({ level, children }) => {
      console.log('[SeoHeading1Text] level, children: ', level, children);
      return (
        <Typography component='h1' variant={`h${level}`}>
          {children}
        </Typography>
      );
    }
  };
  return <ReactMarkdown renderers={renderers}>{text}</ReactMarkdown>;
};

export default SeoHeading1Text;

export const query = graphql`
  fragment ContentfulSeoHeading1Text on ContentfulSeoHeading1Text {
    text {
      json
    }
  }
`;
