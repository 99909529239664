// @flow
import React from 'react';
import classNames from 'classnames';
import { graphql } from 'gatsby';
import queryString from 'query-string';
import Button from '@material-ui/core/Button';

import ContentfulLink from './ContentfulLink';
import { makeStyles } from '@material-ui/core/styles';
import { styles } from './SPButton.Style';

import type { spButton } from '../utils/types';

const useStyles = makeStyles(styles);
const SPButton = (props: spButton) => {
  const classes = useStyles();
  const {
    link,
    style,
    customClasses,
    buttonCaption,
    center,
    ctaFunctions,
    setMsgToSend
  } = props;
  console.log('[spButton]: props: ', props);
  console.log('[spButton]: customClasses: ', customClasses);

  return (
    <>
      <ContentfulLink
        {...link}
        ctaFunctions={ctaFunctions}
        setMsgToSend={setMsgToSend}
      >
        <Button
          className={classNames(
            customClasses ? customClasses.button : null,
            classes.btn
          )}
          color={style.toLowerCase() === 'primary' ? 'primary' : 'secondary'}
          variant='outlined'
        >
          {link.iconPosition === 'Left' && link.icon ? (
            <img
              className={classes.iconLeft}
              height='20'
              src={link.icon.file.url}
              alt={link.icon.description}
            />
          ) : null}
          {link.label}
          {link.iconPosition === 'Right' && link.icon ? (
            <img
              className={classes.iconRight}
              height='20'
              src={link.icon.file.url}
              alt={link.icon.description}
            />
          ) : null}
        </Button>
      </ContentfulLink>
      {buttonCaption ? (
        <p
          className={classNames(
            classes.buttonCaption,
            customClasses ? customClasses.buttonCaption : null,
            center ? 'center' : null
          )}
          dangerouslySetInnerHTML={{
            __html: buttonCaption
          }}
        ></p>
      ) : null}
    </>
  );
};

export default SPButton;

export const query = graphql`
  fragment ContentfulButton on ContentfulButton {
    style
    link {
      url
      label
      icon {
        file {
          url
          contentType
        }
      }
      iconPosition
    }
    buttonCaption
  }
`;
