import React from 'react';
import * as R from 'ramda';
import { graphql } from 'gatsby';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Carousel from 'nuka-carousel';

import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import TextLoop from 'react-text-loop';
import { makeStyles } from '@material-ui/core/styles';
import { styles } from './ContentfulSimpleTextArray.Style';
import ContentfulRichImage from './ContentfulRichImage';
import theme from '../theme';
import type { contentfulImage } from '../utils/types';
import { renderRichText } from '../utils/renderUtils';

type Props = {
  language: {
    en: Array<string>
  }
};

const carouselItems = {
  ContentfulRichImage
};

const ContentfulSimpleTextArray = (props: Props) => {
  console.log('[ContentfulSimpleTextArray] props: ', props);
  const { language } = props;
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  return (
    <TextLoop mask={true} interval={2500}>
      {language && language.en
        ? language.en.map((text, lenIdx) => (
            <span key={`len-${lenIdx}`} className={classes.arrayText}>
              {text}
            </span>
          ))
        : null}
    </TextLoop>
  );
};

export default ContentfulSimpleTextArray;

export const query = graphql`
  fragment ContentfulSimpleTextArray on ContentfulSimpleTextArray {
    language {
      json
    }
  }
`;
