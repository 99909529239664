/* eslint-disable react/no-multi-comp */
// @flow
import React, { useState } from 'react';
import { graphql } from 'gatsby';
import * as R from 'ramda';
import classNames from 'classnames';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { window, document } from 'browser-monads';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import { Typography, Hidden } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import CheckIcon from '@material-ui/icons/Check';
import SPButton from './SPButton';
import { makeStyles } from '@material-ui/core/styles';
import { styles } from './ContentfulPricingSection.Style';

type Props = {
  pricingTable: {
    enableMonthYearSwitch: boolean,
    showOnlyMobilePlans: boolean,
    plans: Array<{
      id: string,
      title: string,
      // ctaLabel: string,
      // subTitle: string,
      monthly: string,
      yearly: string,
      price: string, // used if !enableMonthYearSwitch
      linkMonthly: string,
      linkYearly: string
    }>,
    features: Array<{
      label: string,
      plans: Array<string>
    }>,
    plansMobile: Array<{
      planId: string,
      features: Array<{
        label: string
        // description: string
      }>
    }>
  },
  ctaFunctions: Object
};

const ContentfulPricingSection = (props: Props) => {
  const { pricingTable, ctaFunctions } = props;
  const useStyles = makeStyles(
    styles({
      plansNb: pricingTable.plans.length
    })
  );
  const classes = useStyles();
  console.log('[ContentfulPricingSection] props: ', props);
  const [isPeriodYearly, setIsPeriodYearly] = useState(true);

  return (
    <>
      {pricingTable ? (
        <Grid className={classes.container} container direction='column'>
          {/* <Grid className={classes.yearlyToggle} item xs={12}>
            <Typography variant='h1' className={classes.title}>
              Pricing
            </Typography>
            <Typography variant='h3'>
              Starting at less than $1/day.
              <br />
            </Typography>
          </Grid> */}
          {/* switch monthly / yearly price */}
          {pricingTable.enableMonthYearSwitch ? (
            <Grid className={classes.yearlyToggle} item xs={12}>
              {/*
                If user logged in, then enrich this screen with suggestions for this user.
                On select a plan, redirect to paymentForm page with the selected plan in the state?
              */}
              <Grid alignItems='center' component='label' container spacing={1}>
                <Grid item>Billed Monthly</Grid>
                <Grid item>
                  <Switch
                    checked={isPeriodYearly}
                    color='primary'
                    name='isPeriodYearly'
                    onChange={e => setIsPeriodYearly(e.target.checked)}
                  />
                </Grid>
                <Grid item>Billed Yearly</Grid>
              </Grid>
            </Grid>
          ) : null}

          <Grid item xs={12}>
            {!pricingTable.showOnlyMobilePlans ? (
              <Paper className={classes.gridPaper} elevation={4}>
                {/* Desktop view or comparison grid */}
                <Hidden only={['xs']}>
                  <Grid container direction='row'>
                    <Grid
                      item
                      xs={
                        pricingTable.plans.length === 1
                          ? 4
                          : 12 -
                            pricingTable.plans.length *
                              Math.floor(9 / pricingTable.plans.length)
                      }
                    >
                      <Grid className={classes.featuresList} container>
                        <Grid className={classes.featureHeader} item />
                        {pricingTable.features.map((feature, featureIndex) => (
                          <Grid
                            className={classNames(
                              classes.gridCell,
                              classes.featureDesc,
                              pricingTable.features.length === featureIndex + 1
                                ? 'bottom'
                                : null,
                              feature.isHeader ? classes.emphasisDescr : null
                            )}
                            item
                            key={`feature-${featureIndex}`}
                          >
                            <pre
                              dangerouslySetInnerHTML={{
                                __html: feature.label
                              }}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                    {pricingTable.plans.map((plan, planIndex) => (
                      <Grid
                        item
                        key={`plan-${planIndex}`}
                        xs={
                          pricingTable.plans.length === 1
                            ? 8
                            : Math.floor(9 / pricingTable.plans.length)
                        }
                      >
                        <Grid className={classes.featuresList} container>
                          <Grid className={classes.featureHeader} item>
                            <Typography
                              variant='h2'
                              className={classes.planTitle}
                            >
                              {plan.title}
                            </Typography>
                            {!pricingTable.enableMonthYearSwitch ? (
                              <Typography
                                variant='h3'
                                className={classNames(
                                  classes.planPrice,
                                  plan.yearly === '-1'
                                    ? classes.planPricePAYG
                                    : null
                                )}
                              >
                                {plan.yearly === '-1' ? null : `${plan.yearly}`}
                              </Typography>
                            ) : isPeriodYearly ? (
                              <div className={'discountedPrice'}>
                                <Typography
                                  variant='h3'
                                  className={classNames(
                                    classes.planPrice,
                                    plan.yearly === '-1'
                                      ? classes.planPricePAYG
                                      : null
                                  )}
                                >
                                  {plan.yearly === '-1'
                                    ? null
                                    : `${plan.yearly}`}
                                </Typography>
                                {plan.monthly !== -1 ? (
                                  <Typography
                                    className={classes.striked}
                                    variant='h4'
                                  >
                                    {`${plan.monthly}`}
                                  </Typography>
                                ) : (
                                  <Typography
                                    className={classes.striked}
                                    variant='h4'
                                  >
                                    &nbsp;
                                  </Typography>
                                )}
                              </div>
                            ) : (
                              <div className={'discountedPrice'}>
                                <Typography
                                  variant='h3'
                                  className={classNames(
                                    classes.planPrice,
                                    plan.monthly === '-1'
                                      ? classes.planPricePAYG
                                      : null
                                  )}
                                >
                                  {plan.monthly === '-1'
                                    ? null
                                    : `${plan.monthly}`}
                                </Typography>
                                <Typography
                                  className={classes.hiddenSpace}
                                  variant='h4'
                                >
                                  &nbsp;
                                </Typography>
                              </div>
                            )}
                            <SPButton
                              center
                              style='Primary'
                              link={{
                                url:
                                  plan.id === 'plan3'
                                    ? 'neo://setOpenRequestAccess?open=true&msgToSend=NEO'
                                    : 'neo://setOpenRequestAccess?open=true&msgToSend=NEO',
                                label: 'Get Started'
                              }}
                              buttonCaption={'7-day free trial'}
                              ctaFunctions={ctaFunctions}
                              // setMsgToSend={setMsgToSend}
                              customClasses={{
                                button: classes.topBuy,
                                buttonCaption: classes.buttonCaption
                              }}
                            />
                          </Grid>
                          {pricingTable.features.map(
                            (feature, featureIndex) => (
                              <Grid
                                className={classNames(
                                  classes.gridCell,
                                  classes.featureItem,
                                  pricingTable.features.length ===
                                    featureIndex + 1
                                    ? 'bottom'
                                    : null,
                                  feature.isHeader ? classes.emphasis : null
                                )}
                                item
                                key={`feature-plan${planIndex}-${featureIndex}`}
                              >
                                {!feature.plans ? null : feature.plans[
                                    planIndex
                                  ] === 'checked' ? (
                                  <FontAwesomeIcon
                                    className={classes.checkIcon}
                                    icon={faCheck}
                                  />
                                ) : (
                                  <pre
                                    dangerouslySetInnerHTML={{
                                      __html: feature.plans[planIndex]
                                    }}
                                  />
                                )}
                              </Grid>
                            )
                          )}
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Hidden>

                {/* Mobile view */}
                <Hidden only={['sm', 'md', 'lg', 'xl']}>
                  {pricingTable.plansMobile.map((plan, planIndex) => {
                    const planDef = R.find(R.propEq('id', plan.planId))(
                      pricingTable.plans
                    );
                    return (
                      <div key={planIndex}>
                        {planDef ? (
                          <Grid
                            className={classes.featuresList}
                            container
                            key={`mobile-plan-${planIndex}`}
                          >
                            <Grid className={classes.featureHeader} item>
                              <Typography variant='h2'>
                                {planDef.title}
                              </Typography>
                              {isPeriodYearly ? (
                                <div className={'discountedPrice'}>
                                  <Typography variant='h3'>
                                    {(typeof planDef.yearly === 'number' &&
                                      planDef.yearly !== '-1') ||
                                    typeof planDef.yearly === 'string'
                                      ? `${planDef.yearly}`
                                      : null}
                                  </Typography>
                                  <Typography
                                    className={classes.striked}
                                    variant='h4'
                                  >
                                    {(typeof planDef.yearly === 'number' &&
                                      planDef.yearly !== '-1') ||
                                    typeof planDef.yearly === 'string'
                                      ? `${planDef.monthly}`
                                      : null}
                                  </Typography>
                                </div>
                              ) : (
                                <div className={'discountedPrice'}>
                                  <Typography variant='h3'>
                                    {typeof planDef.monthly === 'number' &&
                                    planDef.monthly === '-1'
                                      ? null
                                      : `${planDef.monthly}`}
                                    {typeof planDef.monthly === 'string'
                                      ? planDef.monthly
                                      : null}
                                  </Typography>
                                  <Typography
                                    className={classes.hiddenSpace}
                                    variant='h4'
                                  >
                                    -
                                  </Typography>
                                </div>
                              )}

                              <SPButton
                                center
                                style='Primary'
                                link={{
                                  url:
                                    planDef.id === 'plan3'
                                      ? 'neo://setOpenRequestAccess?open=true&msgToSend=NEO'
                                      : 'neo://setOpenRequestAccess?open=true&msgToSend=NEO',
                                  label: 'Get Started'
                                }}
                                buttonCaption={'7-day free trial'}
                                ctaFunctions={ctaFunctions}
                                // setMsgToSend={setMsgToSend}
                                customClasses={{
                                  button: classes.topBuy,
                                  buttonCaption: classes.ctaCaption
                                }}
                              />

                              {pricingTable.features.map(
                                (feature, featureIndex) => (
                                  <Grid
                                    className={classNames(
                                      classes.gridCell,
                                      classes.featureItemMobile,
                                      pricingTable.features.length ===
                                        featureIndex + 1
                                        ? 'bottom'
                                        : null
                                      // feature.isHeader ? classes.emphasis : null
                                    )}
                                    item
                                    key={`feature-plan${planIndex}-${featureIndex}`}
                                  >
                                    <div>
                                      <Typography
                                        variant='body1'
                                        className={plan.mobileLabel}
                                      >
                                        <pre
                                          className={classNames(
                                            feature.isHeader
                                              ? classes.featurePlanMobileHeader
                                              : classes.featurePlanMobileLabel
                                          )}
                                          dangerouslySetInnerHTML={{
                                            __html: feature.label
                                          }}
                                        />
                                      </Typography>
                                    </div>
                                    <div>
                                      {!feature.plans ? null : feature.plans[
                                          planIndex
                                        ] === 'checked' ? (
                                        <FontAwesomeIcon
                                          className={classes.checkIcon}
                                          icon={faCheck}
                                        />
                                      ) : (
                                        <pre
                                          className={classes.featurePlanMobile}
                                          dangerouslySetInnerHTML={{
                                            __html: feature.plans[planIndex]
                                          }}
                                        />
                                      )}
                                    </div>
                                  </Grid>
                                )
                              )}
                            </Grid>
                            {/* {plan.features.map((feature, featureIndex) => (
                              <Grid
                                className={classNames(
                                  classes.gridCell,
                                  classes.featureDesc,
                                  plan.features.length === featureIndex + 1
                                    ? 'bottom'
                                    : null
                                )}
                                item
                                key={`mobile-plan${planIndex}-feature${featureIndex}`}
                              >
                                <Typography
                                  style={{ fontWeight: 'bold' }}
                                  variant='body1'
                                >
                                  {feature.label}
                                </Typography>
                              </Grid>
                            ))} */}
                            {/* <Typography variant='body1'>
                              {topUpTable && topUpTable.topUpPlans
                                ? `${topUpTable.topUpPlans.label}: ${topUpTable.topUpPlans.plans[planIndex]}`
                                : null}
                            </Typography> */}
                          </Grid>
                        ) : null}
                      </div>
                    );
                  })}
                </Hidden>
              </Paper>
            ) : null}

            {/* New mobile only mode */}
            {pricingTable.showOnlyMobilePlans ? (
              <div className={classes.mobileOnlyPricingGridHOCtr}>
                <div className={classes.mobileOnlyPricingGridContainer}>
                  {pricingTable.plansMobile.map((plan, planIndex) => {
                    const planDef = R.find(R.propEq('id', plan.planId))(
                      pricingTable.plans
                    );
                    return (
                      <Paper
                        className={classes.mobileOnlyGridPaper}
                        elevation={4}
                        key={planIndex}
                      >
                        {planDef ? (
                          <Grid
                            className={classes.featuresList}
                            container
                            key={`mobile-plan-${planIndex}`}
                          >
                            <Grid
                              className={classes.mobileOnlyFeatureHeader}
                              item
                            >
                              <Typography variant='h2'>
                                {planDef.title}
                              </Typography>
                              {/* {planDef.subTitle ? (
                                <Typography variant='h3'>
                                  {planDef.subTitle}
                                </Typography>
                              ) : null} */}

                              {/* <a
                                href={
                                  isPeriodYearly
                                    ? planDef.linkYearly
                                    : planDef.linkMonthly
                                }
                              >
                                <Button
                                  className={classes.topBuy}
                                  color='primary'
                                  variant='outlined'
                                >
                                  {planDef.ctaLabel ? planDef.ctaLabel : 'Buy'}
                                </Button>
                              </a> */}
                            </Grid>
                            {plan.features.map((feature, featureIndex) => (
                              <Grid
                                className={classNames(
                                  classes.mobileOnlyGridCell,
                                  classes.featureDesc,
                                  plan.features.length === featureIndex + 1
                                    ? 'bottom'
                                    : null
                                )}
                                item
                                key={`mobile-plan${planIndex}-feature${featureIndex}`}
                              >
                                <CheckCircleOutlineIcon />
                                <Typography
                                  className={classes.mobileOnlyFeature}
                                  variant='body1'
                                >
                                  {feature.label}
                                </Typography>
                              </Grid>
                            ))}
                          </Grid>
                        ) : null}
                      </Paper>
                    );
                  })}
                </div>
                {/* <Grid className={classes.tableCaptionCtr} item xs={12}>
                  {pricingTable.tableCaption.split('\n').map(line => (
                    <Typography component='p'>{line}</Typography>
                  ))}
                </Grid> */}
              </div>
            ) : null}
          </Grid>
        </Grid>
      ) : null}
    </>
  );
};

export default ContentfulPricingSection;

export const query = graphql`
  fragment ContentfulPricingSection on ContentfulPricingSection {
    pricingTable {
      enableMonthYearSwitch
      showOnlyMobilePlans
      plans {
        id
        title
        monthly
        yearly
        linkMonthly
        linkYearly
      }
      features {
        isHeader
        label
        plans
      }
      plansMobile {
        planId
        features {
          label
        }
      }
    }
  }
`;
