/* eslint-disable react/no-multi-comp */
// @flow
import React, { useState } from 'react';
import { graphql } from 'gatsby';
import * as R from 'ramda';
import classNames from 'classnames';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { window, document } from 'browser-monads';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import { Typography, Hidden } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import CheckIcon from '@material-ui/icons/Check';
import SPButton from './SPButton';
import { makeStyles } from '@material-ui/core/styles';
import { styles } from './Contentful2RichColumnsSection.Style';

type Props = {
  ctaFunctions: Object
};

const Contentful2RichColumnsSection = (props: Props) => {
  const { ctaFunctions, contentfulMarketingPage } = props;
  const useStyles = makeStyles(styles());
  const classes = useStyles();
  console.log('[Contentful2RichColumnsSection] props: ', props);

  return (
    <React.Fragment>
      {contentfulMarketingPage && contentfulMarketingPage.slug === 'pricing' ? (
        <Grid
          className={classes.rechargesCtr}
          container
          direction='row'
          spacing={4}
        >
          <Grid item xs={12} className={classes.rechargesTitleCtr}>
            <Typography variant='h2'>Add-ons / Credit Recharges</Typography>
            <Typography variant='body1'>
              Valid with all subscriptions - never expire with any valid
              subscriptions
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.creditItemCtr}>
            <div className={classes.itemBodyCtr}>
              <div className={classes.itemBodyLeft}>
                <Typography variant='body1' className={classes.topupCredits}>
                  1000 Credits
                </Typography>
                <Typography variant='body1' className={classes.topupCaption}>
                  $0.04 per Credit
                </Typography>
              </div>
              <div className={classes.itemBodyRight}>
                <Typography variant='body1' className={classes.topupPrice}>
                  $40
                </Typography>
              </div>
            </div>
            <div className={classes.itemFooter}>&nbsp;</div>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.creditItemCtr}>
            <div className={classes.itemBodyCtr}>
              <div className={classes.itemBodyLeft}>
                <Typography variant='body1' className={classes.topupCredits}>
                  5000 Credits
                </Typography>
                <Typography variant='body1' className={classes.topupCaption}>
                  $0.035 per Credit
                </Typography>
              </div>
              <div className={classes.itemBodyRight}>
                <Typography variant='body1' className={classes.topupPrice}>
                  $175
                </Typography>
              </div>
            </div>
            <div className={classes.itemFooter}>&nbsp;</div>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.creditItemCtr}>
            <div className={classes.itemBodyCtr}>
              <div className={classes.itemBodyLeft}>
                <Typography variant='body1' className={classes.topupCredits}>
                  25k Credits
                </Typography>
                <Typography variant='body1' className={classes.topupCaption}>
                  $0.03 per Credit
                </Typography>
              </div>
              <div className={classes.itemBodyRight}>
                <Typography variant='body1' className={classes.topupPrice}>
                  $750
                </Typography>
              </div>
            </div>
            <div className={classes.itemFooter}>&nbsp;</div>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.creditItemCtr}>
            <div className={classes.itemBodyCtr}>
              <div className={classes.itemBodyLeft}>
                <Typography variant='body1' className={classes.topupCredits}>
                  100k Credits
                </Typography>
                <Typography variant='body1' className={classes.topupCaption}>
                  $0.025 per Credit
                </Typography>
              </div>
              <div className={classes.itemBodyRight}>
                <Typography variant='body1' className={classes.topupPrice}>
                  $2,500
                </Typography>
              </div>
            </div>
            <div className={classes.itemFooter}>&nbsp;</div>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.creditItemCtr}>
            <div className={classes.itemBodyCtr}>
              <div className={classes.itemBodyLeft}>
                <Typography variant='body1' className={classes.topupCredits}>
                  500k Credits
                </Typography>
                <Typography variant='body1' className={classes.topupCaption}>
                  $0.02 per Credit
                </Typography>
              </div>
              <div className={classes.itemBodyRight}>
                <Typography variant='body1' className={classes.topupPrice}>
                  $10,000
                </Typography>
              </div>
            </div>
            <div className={classes.itemFooter}>&nbsp;</div>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.creditItemCtr}>
            <div className={classes.itemBodyCtr}>
              <div className={classes.itemBodyLeft}>
                <Typography variant='body1' className={classes.topupCredits}>
                  1M Credits
                </Typography>
                <Typography variant='body1' className={classes.topupCaption}>
                  $0.015 per Credit
                </Typography>
              </div>
              <div className={classes.itemBodyRight}>
                <Typography variant='body1' className={classes.topupPrice}>
                  $15,000
                </Typography>
              </div>
            </div>
            <div className={classes.itemFooter}>&nbsp;</div>
          </Grid>
        </Grid>
      ) : null}
      <Grid
        item
        xs={12}
        className={classNames(
          classes.topMarginSpacing2,
          classes.widthContainer,
          classes.newCenteredSectionCtr
        )}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={4}>
            <Typography variant='h5' className={classes.smallh5}>
              No hidden costs
            </Typography>
            <Typography variant='body1' className={classes.smallBody1}>
              That's right! All prices include taxes and fees.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Typography variant='h5' className={classes.smallh5}>
              Simple pricing
            </Typography>
            <Typography variant='body1' className={classes.smallBody1}>
              1 credit = 1 SMS up to 67 characters. <br />5 credits = 1 image /
              MMS.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Typography variant='h5' className={classes.smallh5}>
              Free undelivered
            </Typography>
            <Typography variant='body1' className={classes.smallBody1}>
              Only pay for your delivered messages.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <div
        className={classNames(
          classes.newCenteredSectionCtr,
          classes.topMarginSpacing6
        )}
      >
        <Typography variant='h5' className={classes.smallh5}>
          Keep your account active
        </Typography>
        <Typography variant='body1' className={classes.smallBody1}>
          Just recharge at least once every 12 months
          <br />
          <br />
        </Typography>
      </div>
    </React.Fragment>
  );
};

export default Contentful2RichColumnsSection;

export const query = graphql`
  fragment Contentful2RichColumnsSection on Contentful2RichColumnsSection {
    transparentBackground
    alignSection
    theme {
      background
      text
      h1
      h2
      h3
    }
    fullWidthTextTop {
      json
    }
    column1 {
      json
    }
    column2 {
      json
    }
    cta {
      ...ContentfulButton
    }
  }
`;
