export const styles = injectedStyles => theme => ({
  container: {
    flexGrow: 1,
    minHeight: 120,
    ...injectedStyles.container,
    backgroundColor: theme.palette.custom.neoViolet.main,
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  smallContainer: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.main
  },
  smallContentCtr: {
    color: 'white',
    textAlign: 'center',
    alignSelf: 'center',
    '& h4, p': {
      fontSize: theme.spacing(2.25),
      display: 'inline',
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.spacing(1.9)
      },
      '& a': {
        color: 'white',
        textDecoration: 'underline'
      }
    }
  },
  contentCtr: {
    ...theme.squareplan.window.width,
    color: 'white',
    textAlign: 'center',
    margin: '0 auto',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2.5),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    '& a': {
      color: 'white',
      textDecoration: 'underline'
    }
  },
  columnCtr: {
    ...theme.squareplan.window.width,
    color: 'white',
    textAlign: 'center',
    margin: '0 auto',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    '& a': {
      color: 'white',
      textDecoration: 'underline'
    }
  },
  colImage: {
    order: 2,
    marginTop: theme.spacing(3)
  },
  order2: {
    order: 2
  },
  image: {
    width: 110,
    marginRight: theme.spacing(3)
  }
  // body
});
