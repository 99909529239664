import React from 'react';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import Carousel from 'react-material-ui-carousel';

import { makeStyles } from '@material-ui/core/styles';
import { styles } from './ContentfulTestimonialsCarousel.Style';
import theme from '../theme';
import type { contentfulImage } from '../utils/types';

type Props = {
  title: string,
  subtitle: string,
  testimonialContent: Object
};

const ContentfulTestimonialsCarousel = (props: Props) => {
  console.log('[ContentfulCarousel] props: ', props);
  const { title, subtitle, testimonialContent } = props;
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  return (
    <Grid
      classes={{
        root: classes.container
      }}
      item
      xs={12}
    >
      <Grid className={classes.wrapper} container>
        {title ? (
          <Typography className={classes.title} variant='h1'>
            {title}
          </Typography>
        ) : null}
        {subtitle ? (
          <Typography className={classes.subtitle} variant='h4'>
            {subtitle}
          </Typography>
        ) : null}
        <Grid className={classes.carouselContainer}>
          <Carousel
            navButtonsAlwaysInvisible
            animation='slide'
            indicators={true}
            interval={7000}
          >
            {testimonialContent.map((testimonial, i) => (
              <Grid key={i}>
                <Typography
                  variant='h5'
                  component='p'
                  className={classes.content}
                >
                  {`"${testimonial.content}"`}
                </Typography>
                <Typography className={classes.author}>
                  {testimonial.author}
                </Typography>
              </Grid>
            ))}
          </Carousel>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ContentfulTestimonialsCarousel;
