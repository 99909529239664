import { window, document } from 'browser-monads';

const HERO_MAX_HEIGHT = 500;
export const styles = injectedStyles => theme => ({
  ...theme.contentfulThemes,
  container: {
    flexGrow: 1,
    position: 'relative',
    // height: window.innerHeight > 900
    //   ? `calc((var(--vh, 1vh) * 100 - ${theme.squareplan.header.height.height}px) * 0.62)`
    //   : 'auto',
    // backgroundColor: injectedStyles.backgroundColor,
    minHeight: HERO_MAX_HEIGHT,
    '& pre': theme.typography.pre,
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      height: 'auto'
    }
  },
  contentCtr: {
    ...theme.squareplan.window.width,
    margin: '0 auto',
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(13)
    }
  },
  title: {
    // color: theme.palette.text.primary,
    fontWeight: 800,
    // fontSize: '3.5rem',
    width: '100%',
    textAlign: 'center',
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.8rem'
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2)
    }
  },
  subtitle: {
    textAlign: 'center',
    marginTop: theme.spacing(-2),
    marginBottom: theme.spacing(8)
  },
  kpiCtr: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'center',
    alignItems: 'flex-start',
    justifyContent: 'space-around',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  kpiCol: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    ...injectedStyles.kpiCol,
    [theme.breakpoints.down('xs')]: {
      width: '62%',
      margin: '16px auto'
    }
  },
  kpi: {
    margin: '0 auto',
    marginBottom: theme.spacing(2),
    ...injectedStyles.kpi,
    color: 'white',
    // padding: theme.spacing(4),
    borderRadius: theme.spacing(16),
    width: theme.spacing(12.5),
    height: theme.spacing(12.5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around'
  },
  kpiTypo: {
    position: 'relative',
    top: theme.spacing(0.5),
    marginBottom: theme.spacing(1)
  },
  label: {
    marginBottom: theme.spacing(4)
  },
  labelTypo: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    maxWidth: 350,
    margin: '0 auto',
    fontSize: '1.4rem',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      fontSize: '1.2rem'
    }
  },
  ctaCaption: {
    fontWeight: 600,
    marginTop: theme.spacing(1),
    fontSize: theme.spacing(1.75)
  },
  ctaCtr: {
    marginTop: theme.spacing(4),
    textAlign: 'center'
  }
});
