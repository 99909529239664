import { PlayCircleFilledWhite } from '@material-ui/icons';
import { window, document } from 'browser-monads';

const MIN_HEIGHT = 600;
export const styles = injectedStyles => theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    position: 'relative',
    minHeight: MIN_HEIGHT,
    // height: window.innerHeight > 900
    //   ? `calc((var(--vh, 1vh) * 100 - ${theme.squareplan.header.height.height}px) * 0.62)`
    //   : 'auto',
    '& pre': theme.typography.pre,
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      height: 'auto'
    }
  },
  containerBackground: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent',
    backgroundImage: injectedStyles.backgroundImage,
    backgroundSize: injectedStyles.backgroundSize || 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top',
    opacity: 1,
    zIndex: -1
  },
  contentCtr: {
    ...theme.squareplan.window.width,
    height: '100%',
    display: 'flex',
    minHeight: 600,
    justifyContent: 'space-between',
    margin: '0 auto',
    paddingTop: theme.spacing(12),
    paddingBottom: theme.spacing(13),
    color: `${
      theme.palette.text[injectedStyles.color || 'secondary']
    } !important`,
    '& * p, & * h1, & * h2': {
      color: `${theme.palette.text[injectedStyles.color || 'secondary']}`
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(10)
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-end'
      // minHeight: 0
    }
  },
  absentTitleNCta: {
    justifyContent: 'space-around'
  },
  titleCtr: {
    display: 'inline',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(6),
    color: `${theme.palette.text[injectedStyles.color || 'secondary']}`,
    '& p': {
      color: `${theme.palette.text[injectedStyles.color || 'secondary']}`
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2)
    }
  },
  embedTitleAsset: {
    position: 'relative',
    bottom: -4,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '2rem',
    height: '2rem'
  },
  title: {
    display: 'inline',
    color: `${theme.palette.text[injectedStyles.color || 'secondary']}`,
    fontWeight: 800,
    textShadow: '0px 0px 10px #00000024',
    // fontSize: '3.5rem',
    // width: '100%',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.8rem'
    }
  },
  quote: {
    ...theme.squareplan.quote(injectedStyles),
    '& *': {
      textShadow: 'none !important'
    },
    '& + h4': {
      textShadow: 'none',
      position: 'relative',
      width: '60%',
      fontWeight: '400 !important',
      margin: '0 auto',
      marginTop: theme.spacing(2),
      padding: `0px ${50 + theme.spacing(3)}px`,
      [theme.breakpoints.down('xs')]: {
        width: 'auto',
        padding: 0
      },
      ...theme.typography.body2
    },
    '& h4': {
      [theme.breakpoints.up('sm')]: {
        maxWidth: '100%'
      }
    }
  },
  bodyText: {
    // fontFamily: ['Bradley Hand ITC Regular', 'sans-serif'].join(','),
    color: `${theme.palette.text[injectedStyles.color || 'secondary']}`,
    textShadow: '0px 3px 18px #000000e8', // 0px 0px 10px #00000024',
    fontWeight: 500,
    fontSize: '1.1rem',
    lineHeight: 1.5,
    [theme.breakpoints.up('sm')]: {
      maxWidth: '50%'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem'
      // textShadow: '0px 0px 10px #00000024'
    },
    '& b': {
      fontWeight: 700,
      fontSize: '1.4rem'
    }
  },
  bodyUl: {
    color: `${theme.palette.text[injectedStyles.color || 'secondary']}`,
    paddingInlineStart: '1.25rem',
    marginBottom: theme.spacing(4)
  },
  ctaBtn: {
    marginTop: theme.spacing(1)
  },
  ctaCaption: {
    color: 'white',
    fontWeight: 600,
    marginTop: theme.spacing(1),
    fontSize: theme.spacing(1.75),
    paddingLeft: theme.spacing(2.5)
  },
  positionRight: {
    textAlign: 'right'
  },
  positionLeft: {
    '& *': {
      textAlign: 'left'
    }
  },
  positionCenter: {
    textAlign: 'center',
    '& p': {
      padding: 0
    }
  }
});
