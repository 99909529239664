// @flow

import React from 'react';
import {Helmet} from 'react-helmet';

const MetaTags = (props) => {
  const { metaTags, browserTabTitle } = props;
  return (
    <Helmet>
      <title data-react-helmet='itemprop,lang' itemProp='name' lang='en'>{browserTabTitle}</title>
      <link rel='apple-touch-icon' sizes='180x180' href='/apple-touch-icon.png' />
      <link rel='icon' type='image/png' sizes='32x32' href='/favicon-32x32.png' />
      <link rel='icon' type='image/png' sizes='16x16' href='/favicon-16x16.png' />
      <link rel='manifest' href='/site.webmanifest' />
      <meta property='description' content={metaTags.description} />
      <meta name='application-name' content={metaTags.ogtitle} />
      <meta name='description' content={metaTags.description} />
      <meta property='og:url' content={metaTags.url} />
      <meta property='og:title' content={metaTags.ogtitle} />
      <meta property='og:site_name' content={metaTags.ogtitle} />
      <meta property='og:description' content={metaTags.ogdescription} />
      <meta property='og:image' content={metaTags.ogimage.file.url} />
      <meta property='og:type' content='website' />
      <meta property='twitter:card' content='summary_large_image' />
      <meta property='twitter:domain' content={metaTags.url} />
      <meta property='twitter:title' content={metaTags.ogtitle} />
      <meta property='twitter:description' content={metaTags.ogtitle} />
      <link rel="apple-touch-icon" sizes="57x57" href="/apple-icon-57x57.png" />
      <link rel="apple-touch-icon" sizes="60x60" href="/apple-icon-60x60.png" />
      <link rel="apple-touch-icon" sizes="72x72" href="/apple-icon-72x72.png" />
      <link rel="apple-touch-icon" sizes="76x76" href="/apple-icon-76x76.png" />
      <link rel="apple-touch-icon" sizes="114x114" href="/apple-icon-114x114.png" />
      <link rel="apple-touch-icon" sizes="120x120" href="apple-icon-120x120.png" />
      <link rel="apple-touch-icon" sizes="144x144" href="/apple-icon-144x144.png" />
      <link rel="apple-touch-icon" sizes="152x152" href="/apple-icon-152x152.png" />
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-icon-180x180.png" />
      <link rel="icon" type="image/png" sizes="192x192"  href="/android-icon-192x192.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="96x96" href="/favicon-96x96.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
    </Helmet>
  );
};

export default MetaTags;
