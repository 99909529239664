export const styles = theme => ({
  tagCtr: {
    marginBottom: theme.spacing(1.5),
    '& a:first-of-type': {
      display: 'block',
      fontWeight: 700,
      fontSize: '1.3rem !important',
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
      }
    },
    '& a:nth-of-type(n+2)': {
      display: 'block',
      lineHeight: '1.2rem',
      marginBottom: theme.spacing(1.5)
    }
  }
});
