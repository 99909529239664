import { window, document } from 'browser-monads';

const HERO_MAX_HEIGHT = 600;
const HERO_MIN_HEIGHT = 490;
export const styles = theme => {
  console.log('window.innerHeight: ', window.innerHeight);
  return {
    container: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.yellow,
      // height: window.innerHeight > 900
      //   ? `calc((var(--vh, 1vh) * 100 - ${theme.squareplan.header.height.height}px) * 0.62)`
      //   : 'auto',
      minHeight: HERO_MIN_HEIGHT,
      '& pre': theme.typography.pre,
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        height: 'auto',
        minHeight: HERO_MIN_HEIGHT
      },
      [theme.breakpoints.down('xs')]: {
        textAlign: 'left'
      }
    },
    contentCtr: {
      ...theme.squareplan.window.width,
      margin: '0 auto',
      paddingTop: theme.spacing(12),
      paddingBottom: theme.spacing(9),
      [theme.breakpoints.down('xs')]: {
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(5),
        textAlign: 'left'
      }
    },
    leftTitlesCol: {
      // minWidth: 500,
      paddingRight: theme.spacing(5),
      '& h1 strong': {
        color: theme.palette.primary.main,
        fontWeight: 800
      },
      [theme.breakpoints.down('md')]: {
        minWidth: '50%'
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(1.75)
      },
      [theme.breakpoints.down('xs')]: {
        paddingRight: 0
      }
    },
    title: {
      color: theme.palette.text.primary,
      fontWeight: 800,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0),
      '& p': {
        marginTop: -theme.spacing(2.5),
        fontSize: '2.4rem'
      },
      [theme.breakpoints.down('md')]: {
        marginTop: -theme.spacing(2.5),
        '& p': {
          fontSize: '2rem'
        }
      },
      [theme.breakpoints.down('sm')]: {
        '& p': {
          fontSize: '1.9rem'
        },
        marginBottom: 0
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(2),
        '& p': {
          fontSize: '1.8rem'
        }
      }
    },
    boldTitle: {
      color: theme.palette.primary.main,
      fontWeight: 800
    },
    subTitle: {
      marginTop: 0,
      marginBottom: theme.spacing(8),
      lineHeight: 1.5,
      fontWeight: 400,
      fontSize: '1.2rem',
      [theme.breakpoints.down('lg')]: {
        marginTop: theme.spacing(5),
        marginBottom: 0,
        paddingRight: theme.spacing(2)
      },
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(5),
        marginBottom: 0,
        paddingRight: theme.spacing(2)
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.1rem',
        marginTop: theme.spacing(3),
        marginBottom: 0,
        paddingRight: theme.spacing(1)
      },
      [theme.breakpoints.down('xs')]: {
        display: 'none'
        // marginTop: theme.spacing(3),
        // marginBottom: theme.spacing(3),
        // fontSize: theme.spacing(2)
      }
    },
    heroImgCtr: {
      position: 'relative'
    },
    heroImgMobileCtr: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3)
    },
    toolAnchorCtr: {
      position: 'relative'
    },
    heroImg: {
      // position: 'absolute',
      right: theme.spacing(2),
      top: theme.spacing(10),
      maxHeight: 400,
      filter: 'drop-shadow(2px 2px 6px hsl(0,0%,0%,60%))',
      [theme.breakpoints.down('lg')]: {
        bottom: theme.spacing(9.5),
        height: 'auto',
        right: theme.spacing(4),
        // width: '50%',
        maxWidth: 550,
        maxHeight: 400,
        border: 0
        // marginBottom: `calc((var(--vh, 1vh) * 20 - ${theme.squareplan.header.height.height}px))`
      },
      [theme.breakpoints.down('md')]: {
        bottom: '10vh',
        right: theme.spacing(4),
        maxWidth: 500,
        maxHeight: 400,
        border: 0
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: 0,
        bottom: 'calc(60px - 5vw)',
        right: `calc(-100px + 8vw)`,
        border: 0,
        width: '51vw',
        maxHeight: 350
      },
      [theme.breakpoints.down('xs')]: {
        position: 'relative',
        marginTop: theme.spacing(2),
        marginBottom: 0,
        // left: -15,
        top: 0,
        bottom: 0,
        width: '100%',
        maxWidth: '100%',
        maxHeight: 'none',
        right: 0,
        left: 0
      }
    },
    ctaBtnCtr: {
      marginTop: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(2)
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(2),
        textAlign: 'center'
      }
    },
    ctaBtn: {
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(1)
      },
      [theme.breakpoints.down('xs')]: {
        marginRight: 0
      }
    },
    ctaCaption: {
      color: theme.palette.text.primary,
      marginTop: theme.spacing(1),
      fontSize: theme.spacing(1.75),
      paddingLeft: theme.spacing(2.5)
      // fontWeight: 700
    }
  };
};
