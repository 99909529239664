/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
// @flow
import React, { useState } from 'react';
import classNames from 'classnames';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Contentful2ColumnSection from './Contentful2ColumnSection';
import type { spButton, contentfulRichText } from '../utils/types';
import SPButton from './SPButton';
import { styles } from './ContentfulTabsSection.Style';

type Props = {
  tabs: Array<Object>
}

const sections = {
  Contentful2ColumnSection
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>{children}</>
      )}
    </div>
  );
}

const ContentfulTabsSection = (props) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const {
    tabs,
    tabsTitles
  } = props;
  const [value, setValue] = useState(0);
  console.log('[ContentfulTabsSection] props: ', props);
  


  return (
    <Grid
      className={classes.container}
      container
    >
      <Grid
        className={classes.contentCtr}
        item
        xs={12}
      >
        <Tabs
          value={value}
          onChange={(e, newVal) => setValue(newVal)}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
        {
          tabsTitles && tabsTitles.length > 0
          ? tabsTitles.map((titleProp, tabIdx) => (
            <Tab
              label={titleProp.title}
              key={`tab-title-${tabIdx}`}
            />
            ))
          : null
        }
        </Tabs>
      </Grid>
      <Grid item xs={12}>
      {
          tabs && tabs.length > 0
          ? tabs.map((tabProps, tabIdx) => {
              console.log('tabProps: ', tabProps);
              const Section = sections[tabProps.__typename];
              console.log('Section: ', Section);
              if (Section) {
                return (
                  <TabPanel
                    index={tabIdx}
                    key={`tab-panel-${tabIdx}`}
                    value={value}
                  >
                    <Section {...tabProps} />
                  </TabPanel>
                );
              }
            })
          : null
        }
      </Grid>
    </Grid>
  );
};

export default ContentfulTabsSection;
