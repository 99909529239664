export const styles = theme => {
  return {
    container: {
      flexGrow: 1,
      minHeight: 500,
      paddingTop: theme.spacing(12),
      paddingBottom: theme.spacing(12)
    },
    transparentBckgd: {
      backgroundColor: 'transparent'
    },
    gradientBckgd: {
      backgroundColor: 'transparent',
      backgroundImage:
        'linear-gradient(180deg, rgba(234,234,234,0.23) 0%, #ffffff 100%)'
    },
    centerContent: {
      textAlign: 'center'
    },
    contentCtr: {
      ...theme.squareplan.window.width,
      margin: '0 auto',
      display: 'block',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        paddingBottom: 0
      }
    },
    contentCtr2: {
      ...theme.squareplan.window.width,
      margin: '0 auto',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        paddingBottom: 0
      },
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(8)
      }
    },
    firstRowCtr: {
      display: 'flex',
      flexWrap: 'nowrap',
      justifyContent: 'space-around',
      [theme.breakpoints.down('xs')]: {
        flexWrap: 'wrap',
        justifyContent: 'center'
      }
    },
    sectionCtr: {
      flexGrow: 1,
      padding: '0 18px',
      [theme.breakpoints.down('xs')]: {
        maxWidth: '100%',
        margin: 'auto',
        '& img.leftImage': {
          marginLeft: 'auto',
          marginRight: 'auto'
        },
        marginBottom: theme.spacing(5),
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(0),
        padding: 0
      },
      '& a': {
        color: '#5c6bc0'
      },
      '& h4': {
        width: '95%',
        display: 'inline-block',
        marginTop: theme.spacing(2)
      }
    },
    rowSpacer: {
      marginTop: theme.spacing(4)
    },
    sectionDescCtr: {
      fontWeight: '400',
      marginBottom: theme.spacing(8)
    },
    leftSideSection: {
      paddingLeft: 0
    },
    rightSideSection: {
      paddingRight: 0
    },
    alignLeft: {
      margin: '0 auto'
    },
    centerText: {
      textAlign: 'center',
      margin: 'auto'
    },
    textCtr: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'left',
      justifyContent: 'space-around',
      [theme.breakpoints.down('xs')]: {
        order: 1
      }
    },
    titleCtr: {
      position: 'relative',
      display: 'block',
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(0),
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(0)
      }
    },
    description: {
      // fontSize: theme.spacing(2.1)
    },
    title: {
      display: 'inline'
    },
    embedTitleAsset: {
      position: 'relative',
      bottom: -4,
      display: 'inline',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '2rem',
      height: '2rem'
    },
    rightCol: {
      paddingLeft: theme.spacing(1),
      textAlign: 'left',
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        paddingLeft: 0
      }
    },
    leftCol: {
      paddingRight: theme.spacing(1),
      textAlign: 'left',
      display: 'flex',
      '& img.leftImage': {
        marginLeft: 0
      },
      [theme.breakpoints.down('xs')]: {
        paddingRight: 0,
        '& img.leftImage': {
          marginLeft: 'auto',
          marginRight: 'auto'
        }
      }
    },
    videoCtr: {
      position: 'relative',
      overflow: 'hidden',
      paddingTop: '56.25%',
      width: '100%'
    },
    videoClass: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      border: 0
    },
    imgCtr: {
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        order: 2,
        maxWidth: '75%',
        margin: '0 auto',
        marginTop: theme.spacing(2)
      }
    },
    img: {
      maxWidth: '100%',
      display: 'block',
      margin: 'auto',
      [theme.breakpoints.down('xs')]: {
        maxWidth: '100%'
      }
    },
    ctaCtr: {
      marginTop: theme.spacing(5.62)
    },
    ctaBtn: {
      marginTop: theme.spacing(1)
    },
    ...theme.contentfulThemes
  };
};
