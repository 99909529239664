export const styles = theme => {
  console.log('theme: ', theme);
  return {
    container: {
      backgroundColor: 'transparent',
      backgroundImage:
        'linear-gradient(180deg, rgba(234,234,234,0.23) 0%, #ffffff 100%)',
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(10),
      '& a': {
        textDecoration: 'underline'
      }
    },
    firstContainer: {
      backgroundColor: 'transparent',
      backgroundImage:
        'linear-gradient(180deg, rgba(234,234,234,0.23) 0%, #ffffff 100%)',
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(9)
    },
    isTransparentBackground: {
      backgroundColor: 'transparent',
      backgroundImage: 'none'
    },
    contentCtr: {
      ...theme.squareplan.window.width,
      margin: '0 auto',
      display: 'block',
      paddingBottom: 0,
      [theme.breakpoints.down('sm')]: {
        paddingBottom: 0
      }
    },
    textRow: {
      textAlign: 'left',
      maxWidth: '100%',
      [theme.breakpoints.down('xs')]: {
        order: 1,
        '& img': {
          maxWidth: '100%'
        }
      }
    },
    mediaRow: {
      textAlign: 'center',
      maxWidth: '64%',
      [theme.breakpoints.down('xs')]: {
        maxWidth: '100%'
      }
    },
    titleCtr: {
      position: 'relative',
      justifyContent: 'flex-start',
      alignItems: 'center',
      width: '100%',
      textAlign: 'center',
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(0),
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(2)
      },
      '& blockquote': {
        width: '100%',
        minWidth: 450,
        margin: '0 auto',
        '& p, & span, & strong': {
          ...theme.typography.h3,
          fontStyle: 'italic',
          fontSize: '1.6rem',
          fontWeight: 700,
          fontFamily: ['Product Sans', 'sans-serif'].join(','),
          whiteSpace: 'pre-wrap',
          lineHeight: 1.5,
          marginBottom: theme.spacing(0.75)
        },
        '& strong': {
          color: theme.palette.primary.main
        },
        [theme.breakpoints.down('sm')]: {
          width: '75%'
        },
        [theme.breakpoints.down('xs')]: {
          width: '80vw',
          '& p, & span, & strong': {
            fontSize: '1.3rem !important'
          }
        }
      }
    },
    alignLeft: {
      textAlign: 'left'
    },
    centerText: {
      textAlign: 'center',
      margin: 'auto'
    },
    title: {
      display: 'block'
    },
    h1: {
      fontSize: theme.spacing(6.2),
      wordSpacing: theme.spacing(1),
      marginTop: theme.spacing(4)
    },
    embedTitleAsset: {
      position: 'relative',
      bottom: -4,
      display: 'inline',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '2rem',
      height: '2rem'
    },
    rightCol: {
      paddingLeft: theme.spacing(1),
      textAlign: 'left',
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        paddingLeft: 0
      }
    },
    leftCol: {
      paddingRight: theme.spacing(1),
      textAlign: 'left',
      display: 'flex',
      '& img.leftImage': {
        marginLeft: 0
      },
      [theme.breakpoints.down('xs')]: {
        paddingRight: 0,
        '& img.leftImage': {
          marginLeft: 'auto',
          marginRight: 'auto'
        }
      }
    },
    videoCtr: {
      margin: '0 auto',
      marginTop: theme.spacing(4),
      position: 'relative',
      overflow: 'hidden',
      paddingTop: '56.25%',
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    },
    videoClass: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      border: 0
    },
    imgCtr: {
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        order: 2,
        maxWidth: '75%',
        margin: '0 auto',
        marginTop: theme.spacing(2)
      }
    },
    img: {
      marginTop: theme.spacing(4),
      maxWidth: '90%',
      margin: '0 auto',
      [theme.breakpoints.down('xs')]: {
        maxWidth: '90%'
      }
    },
    ctaCtr: {
      marginTop: theme.spacing(5.62),
      '& a': {
        textDecoration: 'none'
      },
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center'
      }
    },
    insideCtaCtr: {
      marginTop: theme.spacing(5.62),
      marginBottom: theme.spacing(5.62)
    },
    reduceBottomPadding: {
      paddingBottom: theme.spacing(2)
    },
    ...theme.contentfulThemes
  };
};
