/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
// @flow
import React from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import Grid from '@material-ui/core/Grid';

import { renderRichText } from '../utils/renderUtils';
import useSiteMetadata from '../utils/use-site-metadata';
import { makeStyles } from '@material-ui/core/styles';
import { styles } from './ContentfulFooterRichText.Style';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core';
import type { contentfulRichText } from '../utils/types';

type Props = {
  content: Object
};

const useStyles = makeStyles(styles);

const ContentfulFooterRichText = (props: Props) => {
  const classes = useStyles();
  const { content, generalPages, toolsPages, caseStudyPages } = props;
  const siteMetadata = useSiteMetadata();
  console.log('[ContentfulFooterRichText] props: ', props);
  console.log('[ContentfulFooterRichText] siteMetadata', siteMetadata);

  const RenderRichLinks = () => {
    return (
      <>
        <div className={classes.tagCtr}>
          {content && content.json !== '' ? renderRichText(content.json) : null}
        </div>
      </>
    );
  };

  return (
    <Grid className={classNames(classes.container)} item xs={12}>
      <Grid container direction='column'>
        <RenderRichLinks />
      </Grid>
    </Grid>
  );
};

export default ContentfulFooterRichText;

// TODO: Move this to its own component "Tools" and reference to the graphql spread here. Create a "Tools Section" model in contentful
export const query = graphql`
  fragment ContentfulFooterRichText on ContentfulFooterRichText {
    content {
      json
    }
  }
`;
