// @flow

export const styles = (theme: Object) => {
  return {
    container: {
      flexGrow: 1,
      backgroundColor: 'transparent',
      minHeight: 500
    },
    contentCtr: {
      marginTop: theme.spacing(8)
    }
  };
};
