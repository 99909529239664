/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
// @flow
import React from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import Grid from '@material-ui/core/Grid';

import { renderRichText } from '../utils/renderUtils';
import useSiteMetadata from '../utils/use-site-metadata';
import { makeStyles } from '@material-ui/core/styles';
import { styles } from './ContentfulRichAutoListPerTagSection.Style';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core';
import type { contentfulRichText } from '../utils/types';

type Props = {};

const useStyles = makeStyles(styles);

const ContentfulRichAutoListPerTagSection = (props: Props) => {
  const classes = useStyles();
  const {
    contentBeforeLinks,
    tag,
    alignSection,
    toolsPages,
    howtoPages,
    caseStudyPages
  } = props;
  const tagListsMap = {
    Tools: toolsPages,
    'How To': howtoPages,
    'Case Study': caseStudyPages
  };
  const siteMetadata = useSiteMetadata();
  console.log('[ContentfulRichAutoListPerTagSection] props: ', props);
  console.log(
    '[ContentfulRichAutoListPerTagSection] siteMetadata',
    siteMetadata
  );

  const RenderRichLink = () => {
    return (
      <Grid
        className={classNames(
          classes.contentCtr,
          alignSection,
          classes.LinksSectionCtr
        )}
        item
        xs={12}
      >
        {tagListsMap &&
          tagListsMap[tag] &&
          tagListsMap[tag].edges.map((howtoPage, index) => (
            <>
              <a
                href={`/${howtoPage.node.slug}`}
                className={classNames(classes.toolCtr)}
                key={`howtoPages-${index}`}
              >
                <div className={classes.toolAnchorCtr}>
                  {howtoPage.node.metaTags.ogimage ? (
                    <img
                      className={classes.toolImg}
                      src={howtoPage.node.metaTags.ogimage.file.url}
                    />
                  ) : null}

                  <div className={classes.toolCaption}>
                    {howtoPage.node.metaTags
                      ? howtoPage.node.metaTags.ogtitle
                      : 'Surprise!'}
                  </div>
                </div>
              </a>
            </>
          ))}
      </Grid>
    );
  };

  return (
    <Grid className={classNames(classes.container)} item xs={12}>
      <Grid container direction='column'>
        {contentBeforeLinks && contentBeforeLinks.json ? (
          <Grid
            className={classNames(
              classes.contentCtr,
              alignSection,
              classes.topContentCtr
            )}
            item
            xs={12}
          >
            {contentBeforeLinks
              ? renderRichText(contentBeforeLinks.json)
              : null}
          </Grid>
        ) : null}

        <RenderRichLink />
      </Grid>
    </Grid>
  );
};

export default ContentfulRichAutoListPerTagSection;

// TODO: Move this to its own component "Tools" and reference to the graphql spread here. Create a "Tools Section" model in contentful
export const query = graphql`
  fragment ContentfulRichAutoListPerTagSection on ContentfulRichAutoListPerTagSection {
    entryTitle
    contentBeforeLinks {
      json
    }
    tag
    alignSection
  }
`;
