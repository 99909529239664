/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
// @flow
import React from 'react';
import { graphql } from 'gatsby';
import YouTube from 'react-youtube';
import classNames from 'classnames';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Grid from '@material-ui/core/Grid';
import { window, document } from 'browser-monads';
import { makeStyles } from '@material-ui/core/styles';
import { styles } from './Contentful1ColumnSection.Style';
import { Typography } from '@material-ui/core';

import ContentfulRichImage from './ContentfulRichImage';
import SPButton from './SPButton';
import { renderRichText } from '../utils/renderUtils';

import type { spButton, contentfulRichText } from '../utils/types';
type Props = {
  description: contentfulRichText,
  c1csTitle: contentfulRichText,
  c1csCta: spButton,
  firstSection: boolean,
  ctaFunctions: Object,
  isTransparentBackground: boolean
};

type TextRowProps = {
  classes: Object,
  c1csTitle: contentfulRichText
};

const TextRow = (props: TextRowProps) => {
  const {
    classes,
    c1csTitle,
    columnDescription,
    alignSectionLeft,
    centerText,
    isTransparentBackground,
    firstSection
  } = props;
  console.log('[1col] theme: ', props.theme);
  return (
    <Grid item xs={12} sm={6} className={classNames(classes.textRow)}>
      <div
        className={classNames(
          classes.titleCtr,
          !alignSectionLeft && centerText
            ? classes.centerText
            : classes.alignLeft
        )}
      >
        {c1csTitle ? renderRichText(c1csTitle.json) : null}
      </div>
      <div
        className={classNames(
          !alignSectionLeft && centerText
            ? classes.centerText
            : classes.alignLeft
        )}
      >
        {columnDescription ? renderRichText(columnDescription.json) : null}
      </div>
    </Grid>
  );
};

const MediaRow = props => {
  const {
    classes,
    columnImage,
    alignSectionLeft,
    columnEmbedVideo,
    c1csCta,
    ctaFirst,
    ctaFunctions
  } = props;
  console.log(
    '[1colSection] ImgCol: image, column2EmbedVideo: ',
    columnImage,
    columnEmbedVideo
  );
  return (
    <Grid
      item
      xs={12}
      sm={6}
      className={classNames(
        classes.mediaRow,
        alignSectionLeft ? classes.alignLeft : classes.centerText
      )}
    >
      {c1csCta && ctaFirst ? (
        <Grid
          classes={{
            root: classNames(
              classes.insideCtaCtr,
              alignSectionLeft ? classes.alignLeft : classes.centerText
            )
          }}
          item
          xs={12}
        >
          <SPButton
            center={alignSectionLeft ? false : true}
            {...c1csCta}
            ctaFunctions={ctaFunctions}
          />
        </Grid>
      ) : null}
      {columnEmbedVideo ? (
        <YouTube
          // opts={{
          //   width: 300
          // }}
          className={classes.videoClass}
          containerClassName={classes.videoCtr}
          videoId={columnEmbedVideo}
        />
      ) : null}
      {columnImage ? (
        <img
          className={classNames(
            alignSectionLeft ? 'leftImage' : 'centerImage',
            classes.img
          )}
          src={columnImage.file.url}
        />
      ) : null}
      {c1csCta && !ctaFirst ? (
        <Grid
          classes={{
            root: classNames(
              classes.ctaCtr,
              alignSectionLeft ? classes.alignLeft : classes.centerText
            )
          }}
          item
          xs={12}
        >
          <SPButton
            center={alignSectionLeft ? false : true}
            {...c1csCta}
            ctaFunctions={ctaFunctions}
          />
        </Grid>
      ) : null}
    </Grid>
  );
};

const useStyles = makeStyles(styles);
const Contentful1ColumnSection = (props: Props) => {
  const classes = useStyles();
  const {
    firstSection,
    isTransparentBackground,
    reduceBottomPadding,
    theme
  } = props;
  console.log('[Contentful1ColumnSection] props: ', props);

  return (
    <Grid
      className={classNames(
        firstSection ? classes.firstContainer : classes.container,
        isTransparentBackground ? classes.isTransparentBackground : null,
        reduceBottomPadding ? classes.reduceBottomPadding : null,
        theme ? classes[`background${theme.background}`] : null,
        theme ? classes[`text${theme.text}`] : null,
        theme ? classes[`h1${theme.h1}`] : null,
        theme ? classes[`h2${theme.h2}`] : null,
        theme ? classes[`h3${theme.h3}`] : null
      )}
      container
    >
      <Grid className={classes.contentCtr} container>
        <TextRow {...props} classes={classes} />
        <MediaRow {...props} classes={classes} />
      </Grid>
    </Grid>
  );
};

export default Contentful1ColumnSection;

export const query = graphql`
  fragment Contentful1ColumnSection on Contentful1ColumnSection {
    alignSectionLeft
    theme {
      background
      text
      h1
      h2
      h3
    }
    centerText
    ctaCaption
    ctaFirst
    firstSection
    reduceBottomPadding
    isTransparentBackground
    c1csTitle: colTitle {
      json
    }
    columnDescription {
      json
    }
    columnImage {
      description
      file {
        url
        contentType
      }
    }
    columnEmbedVideo
    c1csCta: cta {
      ...ContentfulButton
    }
  }
`;
