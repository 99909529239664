// @flow
import React from 'react';
import * as R from 'ramda';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { window, document } from 'browser-monads';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLinkedinIn,
  faTwitter,
  faMedium,
  faFacebook,
  faYoutube,
  faInstagram
} from '@fortawesome/free-brands-svg-icons';

import ContentfulFooterAutoTagList from './ContentfulFooterAutoTagList';
import ContentfulFooterRichText from './ContentfulFooterRichText';
import Logo from '../images/askneo-logo.png';
import { makeStyles } from '@material-ui/core/styles';
import { renderRichText } from '../utils/renderUtils';
import { styles } from './Footer.Style';

import type { spLink, contentfulRichText } from '../utils/types';

type Props = {
  col1Content: contentfulRichText,
  col1ContentMobile: contentfulRichText,
  col2Links: Array<spLink>,
  col3Links: Array<spLink>,
  col4Links: Array<spLink>,
  col5SocialLinks: Array<spLink>
};

const sections = {
  ContentfulFooterAutoTagList,
  ContentfulFooterRichText
};

const SOCIAL_ICONS = {
  faLinkedinIn: faLinkedinIn,
  faFacebook: faFacebook,
  faInstagram: faInstagram,
  faTwitter: faTwitter,
  faMedium: faMedium,
  faYouTube: faYoutube
};
const useStyles = makeStyles(styles);
const Footer = (props: Props) => {
  const classes = useStyles();
  const {
    col1Content,
    col1ContentMobile,
    col2Links,
    col3Links,
    col4Links,
    columns,
    col5SocialLinks
  } = props;
  console.log('[Footer] props: ', props);

  const renderContent = {
    renderNode: {
      // eslint-disable-next-line react/display-name
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Typography className={classes.subTitle} variant='body1' component='h3'>
          {children}
        </Typography>
      )
    }
  };

  return (
    <footer className={classes.container} id='footer'>
      <Grid className={classes.contentCtr} container spacing={2}>
        <Grid item xs={12} sm={3}>
          <Grid container>
            <Grid item xs={12}>
              <img
                className={classes.logoImg}
                height={35}
                src={Logo}
                alt='logo'
              />
            </Grid>
            <Grid item xs={12} className={classes.col1}>
              {
                <>
                  {/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                    window.navigator.userAgent
                  ) ? (
                    <>
                      {col1ContentMobile
                        ? renderRichText(col1ContentMobile.json)
                        : null}
                    </>
                  ) : (
                    <>{col1Content ? renderRichText(col1Content.json) : null}</>
                  )}
                </>
              }
              <div>
                <Grid
                  className={classes.socialLinksCtr}
                  container
                  direction='row'
                >
                  {col5SocialLinks
                    ? col5SocialLinks.map((link, linkIndex) => (
                        <Grid item key={`col5-link-${linkIndex}`}>
                          <a href={link.url} target='_blank'>
                            <FontAwesomeIcon icon={SOCIAL_ICONS[link.label]} />
                          </a>
                        </Grid>
                      ))
                    : null}
                </Grid>
              </div>
              <Grid className={classes.rights} item>
                Askneo Inc. <span className={classes.copyright}>©</span> 2022
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={9} className={classes.columnsCtr}>
          {columns &&
            columns.map((ColumnSection, index) => {
              const Section = sections[ColumnSection.__typename];
              return (
                <div
                  style={{ width: `${100 / columns.length}%` }}
                  className={classes.footerColumns}
                  key={`columns-${index}`}
                >
                  {Section ? <Section {...ColumnSection} {...props} /> : null}
                </div>
              );
            })}
        </Grid>
      </Grid>
    </footer>
  );
};

export default Footer;

export const query = graphql`
  fragment ContentfulFooter on ContentfulFooter {
    col1Content {
      json
    }
    col1ContentMobile {
      json
    }
    columns {
      __typename
      ...ContentfulFooterAutoTagList
      ...ContentfulFooterRichText
    }
    col5SocialLinks {
      label
      url
    }
  }
`;
