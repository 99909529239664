export const styles = theme => ({
  tagTitle: {
    '& p, &': {
      fontWeight: 700,
      fontSize: '1.3rem !important'
    },
    marginBottom: theme.spacing(3),
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    }
  },
  tagCtr: {
    marginBottom: theme.spacing(1.5),
    lineHeight: '1.2rem'
  }
});
