// @flow
import React, { Component, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import * as R from 'ramda';
import Grid from '@material-ui/core/Grid';

import Header from '../components/Header';
import Footer from '../components/Footer';
import ContentfulAccordion from '../components/ContentfulAccordion';
import ContentfulHeroSection from '../components/ContentfulHeroSection';
import ContentfulSidebar from '../components/ContentfulSidebar';
import ContentfulHeroSectionCenteredWBackgroundImg from '../components/ContentfulHeroSectionCenteredWBackgroundImg';
import Contentful3ColumnSection from '../components/Contentful3ColumnSection';
import Contentful2ColumnSection from '../components/Contentful2ColumnSection';
import Contentful2RichColumnsSection from '../components/Contentful2RichColumnsSection';
import Contentful1ColumnSection from '../components/Contentful1ColumnSection';
import ContentfulBannerSection from '../components/ContentfulBannerSection';
import ContentfulPreFooterSection from '../components/ContentfulPreFooterSection';
import ContentfulPricingSection from '../components/ContentfulPricingSection';
import ContentfulKpIsSection from '../components/ContentfulKpIsSection';
import ContentfulTestimonialsCarousel from '../components/ContentfulTestimonialsCarousel';
import ContentfulSixIconBoxSection from '../components/ContentfulSixIconBoxSection';
import ContentfulBackgroundImageSection from '../components/ContentfulBackgroundImageSection';
import ContentfulTabsSection from '../components/ContentfulTabsSection';
import ContentfulOtherLinksPerTagSection from '../components/ContentfulOtherLinksPerTagSection';
import ContentfulRichAutoListPerTagSection from '../components/ContentfulRichAutoListPerTagSection';
import ContentfulCarouselSection from '../components/ContentfulCarouselSection';
import Logo from '../images/squareplan-logo.png';

import Button from '@material-ui/core/Button';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';
import { styles } from '../components/index.Style';
import MetaTags from '../components/MetaTags';

type Props = {};

const sections = {
  ContentfulAccordion,
  ContentfulSidebar,
  Contentful3ColumnSection,
  Contentful2ColumnSection,
  Contentful2RichColumnsSection,
  Contentful1ColumnSection,
  ContentfulBannerSection,
  ContentfulPreFooterSection,
  ContentfulHeroSection,
  ContentfulHeroSectionCenteredWBackgroundImg,
  ContentfulPricingSection,
  ContentfulKpIsSection,
  ContentfulSixIconBoxSection,
  ContentfulTestimonialsCarousel,
  ContentfulBackgroundImageSection,
  ContentfulTabsSection,
  ContentfulOtherLinksPerTagSection,
  ContentfulRichAutoListPerTagSection,
  ContentfulCarouselSection
};

const useStyles = makeStyles(styles);
const MarketingPage = (props: Props) => {
  const classes = useStyles();
  console.log('props: ', props);
  // console.log('[location]: ', R.pick(['location'])(props));
  const {
    slug,
    metaTags,
    browserTabTitle,
    columnSections,
    whiteBackground,
    header,
    footer
  } = props.data.contentfulMarketingPage;

  const [openRequestAccess, setOpenRequestAccess] = useState(false);
  const [msgToSend, setMsgToSend] = useState('NEO');

  const handleClose = () => {
    setOpenRequestAccess(false);
  };

  console.log('[root] openRequestAccess: ', openRequestAccess);
  return (
    <div>
      <MetaTags browserTabTitle={browserTabTitle} metaTags={metaTags} />
      <Header
        {...header}
        ctaFunctions={{
          setOpenRequestAccess: setOpenRequestAccess
        }}
      />
      <div
        className={
          whiteBackground ? classes.pageContainerWhite : classes.pageContainer
        }
      >
        <Grid className={classes.pushFooterToBottom} container>
          {columnSections
            ? columnSections.map((ColumnSection, index) => {
                const Section = sections[ColumnSection.__typename];
                return (
                  <Grid item key={`col2section-${index}`} xs={12}>
                    {Section ? (
                      <Section
                        sectionIdx={index}
                        {...ColumnSection}
                        {...props.data}
                        {...R.pick(['location'])(props)}
                        ctaFunctions={{
                          setOpenRequestAccess: setOpenRequestAccess
                        }}
                        setMsgToSend={setMsgToSend}
                      />
                    ) : null}
                  </Grid>
                );
              })
            : null}
        </Grid>
      </div>
      <Dialog
        open={openRequestAccess}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        classes={{ paper: classes.ctaDialogCtr }}
      >
        <DialogTitle id='alert-dialog-title'>
          <Typography variant='h3'>{'Get Started'}</Typography>
        </DialogTitle>
        <DialogContent
          classes={{
            root: classes.ctaDialogContentText
          }}
        >
          {/* <DialogContentText
            id='alert-dialog-description'
          > */}
          <Typography variant='h5'>
            {'1. Get your mobile phone out of your pocket :)'}
          </Typography>
          <Typography variant='h5'>
            {`2. Text "${msgToSend}" to `}
            <a href={`sms:+19174515515&body=${msgToSend}`}>(917) 451-5515</a>
          </Typography>
          <br />
          <Typography variant='h5'>{'Good luck!'}</Typography>
          {/* </DialogContentText> */}
        </DialogContent>
        <DialogActions classes={{ root: classes.ctaDialogActionsCtr }}>
          <Button
            onClick={handleClose}
            color='primary'
            autoFocus
            variant='outlined'
            className={classes.ctaBtn}
          >
            Done!
          </Button>
        </DialogActions>
      </Dialog>

      <Footer
        {...footer}
        {...props.data}
        ctaFunctions={{
          setOpenRequestAccess: setOpenRequestAccess
        }}
      />
    </div>
  );
};

export const query = graphql`
  query marketingPageLayoutQueryAndAllContentfulMarketingPage($slug: String!) {
    generalPages: allContentfulMarketingPage(
      sort: { fields: updatedAt, order: DESC }
      filter: { tags: { eq: "General" } }
    ) {
      edges {
        node {
          slug
          tags
          metaTags {
            description
            ogtitle
            ogimage {
              file {
                url
              }
            }
          }
        }
      }
    }
    howtoPages: allContentfulMarketingPage(
      sort: { fields: updatedAt, order: DESC }
      filter: { tags: { eq: "How To" } }
    ) {
      edges {
        node {
          slug
          tags
          metaTags {
            description
            ogtitle
            ogimage {
              file {
                url
              }
            }
          }
        }
      }
    }
    toolsPages: allContentfulMarketingPage(
      sort: { fields: updatedAt, order: DESC }
      filter: { tags: { eq: "Tools" } }
    ) {
      edges {
        node {
          slug
          tags
          metaTags {
            description
            ogtitle
            ogimage {
              file {
                url
              }
            }
          }
        }
      }
    }
    caseStudyPages: allContentfulMarketingPage(
      filter: { tags: { eq: "Case Study" } }
    ) {
      edges {
        node {
          slug
          tags
          metaTags {
            description
            ogtitle
            ogimage {
              file {
                url
              }
            }
          }
        }
      }
    }
    contentfulMarketingPage(slug: { eq: $slug }) {
      slug
      tags
      metaTags {
        description
        ogurl
        ogtitle
        ogsiteName
        ogdescription
        ogimage {
          file {
            url
          }
        }
      }
      whiteBackground
      browserTabTitle
      header {
        desktopTopLinks {
          label
          url
          sublinks {
            label
            url
          }
        }
        showTopBanner
        topBanner {
          backgroundColor
          col1: col1content {
            json
          }
          col2: col2content {
            json
          }
          col3: col3content {
            json
          }
        }
        desktopCtaButtons {
          ... on Node {
            ... on ContentfulLink {
              label
              url
            }
            ... on ContentfulButton {
              style
              link {
                label
                url
              }
            }
          }
        }
        mobilePopupLinks {
          label
          url
          sublinks {
            label
            url
          }
        }
      }
      columnSections {
        __typename
        ...ContentfulHeroSection
        ...ContentfulAccordion
        ...ContentfulSidebar
        ...Contentful1ColumnSection
        ...Contentful2ColumnSection
        ...Contentful3ColumnSection
        ...ContentfulKpIsSection
        ...ContentfulOtherLinksPerTagSection
        ...ContentfulRichAutoListPerTagSection
        ...ContentfulCarouselSection
        ... on ContentfulTestimonialsCarousel {
          title
          subtitle
          testimonialContent: childrenContentfulTestimonialsCarouselContentJsonNode {
            content
            author
          }
        }
        ...ContentfulSixIconBoxSection
        ...ContentfulBannerSection
        ... on ContentfulTabsSection {
          tabs {
            __typename
            ... on Contentful2ColumnSection {
              showTextFirst
              c2csTitle: title {
                json
              }
              description {
                json
              }
              image {
                description
                file {
                  url
                  contentType
                }
              }
              column2EmbedVideo
              c2csCta: cta {
                style
                link {
                  url
                  label
                  icon {
                    description
                    file {
                      url
                      contentType
                    }
                  }
                  iconPosition
                }
              }
            }
          }
          tabsTitles {
            title
          }
        }
        ... on ContentfulPreFooterSection {
          title
          body {
            json
          }
          mainSection
        }
        ... on ContentfulHeroSectionCenteredWBackgroundImg {
          backgroundImage {
            description
            file {
              url
              contentType
            }
          }
          title
          subTitle {
            json
          }
        }
        ...ContentfulPricingSection
        ...Contentful2RichColumnsSection
        ... on ContentfulBackgroundImageSection {
          ctaCaption
          backgroundImage {
            description
            file {
              url
              contentType
            }
          }
          backgroundSize
          color
          cbisTitle: title {
            json
          }
          cbisTitlePosition: titlePosition
          cbisBody: body {
            json
          }
          cbisBodyPosition: bodyPosition
          cbisCta: cta {
            style
            link {
              url
              label
              icon {
                description
                file {
                  url
                  contentType
                }
              }
              iconPosition
            }
          }
          cbisCtaPosition: ctaPosition
        }
      }
      footer {
        ...ContentfulFooter
      }
    }
  }
`;

export default MarketingPage;
