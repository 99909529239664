/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
// @flow
// renders contentful rich content objects

import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import Typography from '@material-ui/core/Typography';
import ContentfulRichImage from '../components/ContentfulRichImage';
import ContentfulHtmlSnippet from '../components/ContentfulHtmlSnippet';
import ResponsiveTextablePhone from '../components/ResponsiveTextablePhone';
import ContentfulSimpleTextArray from '../components/ContentfulSimpleTextArray';
import SeoHeading1Text from '../components/SeoHeading1Text';
import SeoHeading2Text from '../components/SeoHeading2Text';
import SeoHeading3Text from '../components/SeoHeading3Text';
import SeoHeading4Text from '../components/SeoHeading4Text';
import SeoHeading5Text from '../components/SeoHeading5Text';
import SeoHeading6Text from '../components/SeoHeading6Text';
import { capitalizeFirstLetter, extractLanguage } from '../utils/stringUtils';

const entries = {
  ResponsiveTextablePhoneNumber: ResponsiveTextablePhone,
  HtmlSnippet: ContentfulHtmlSnippet,
  SimpleTextArray: ContentfulSimpleTextArray,
  RichImage: ContentfulRichImage,
  SeoHeading1Text,
  SeoHeading2Text,
  SeoHeading3Text,
  SeoHeading4Text,
  SeoHeading5Text,
  SeoHeading6Text
};

/**
 * 
 * @param {*} json 
 * @param {*} props 
 * props: {
      align: 'left',
      variant: 'body1',
      component: 'p',
      classes: {
        root: classes.marginBottom4
      }
    }
 */
const renderText = (json, props) =>
  documentToReactComponents(json, {
    renderNode: {
      // eslint-disable-next-line react/display-name
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Typography
          align={props.align}
          variant={props.variant}
          component={props.component}
          gutterBottom={props.gutterBottom}
          classes={props.classes}
        >
          {children}
        </Typography>
      )
    }
  });

/**
 * 
 * @param {*} json 
 * @param {*} props 
 * {
      [BLOCKS.PARAGRAPH]: {
        align: 'left',
        variant: 'body1',
        component: 'p',
        classes: {
          root: classes.marginBottom4
        }
      }
    }
 */
const defaultRichTextProps = {
  [BLOCKS.HEADING_1]: {
    variant: 'h1',
    component: 'p'
    // gutterBottom: true
  },
  [BLOCKS.HEADING_2]: {
    variant: 'h2',
    component: 'p'
    // gutterBottom: true
  },
  [BLOCKS.HEADING_3]: {
    variant: 'h3',
    component: 'p'
    // gutterBottom: true
  },
  [BLOCKS.HEADING_4]: {
    variant: 'h4',
    component: 'p'
    // gutterBottom: true
  },
  [BLOCKS.HEADING_5]: {
    variant: 'h5',
    component: 'p'
    // gutterBottom: true
  },
  [BLOCKS.HEADING_6]: {
    variant: 'h6',
    component: 'p'
    // gutterBottom: true
  },
  [BLOCKS.PARAGRAPH]: {
    variant: 'body1',
    component: 'p'
    // gutterBottom: true
  }
};

type Props = {
  handleButtonSelection?: Function // used for embed CTAButton
};
const renderRichText = (json, props?: Props, classes?: Object = {}) => {
  const fullProps = {
    ...defaultRichTextProps,
    ...props
  };
  // console.log('[renderRichText] json: ', json);
  return documentToReactComponents(json, {
    renderMark: {
      [MARKS.BOLD]: text => <strong>{text}</strong>
    },
    renderNode: {
      // eslint-disable-next-line react/display-name
      [BLOCKS.EMBEDDED_ASSET]: node => {
        // console.log('[renderRichText] BLOCKS.EMBEDDED_ASSET node: ', node);
        // console.log(
        //   '[renderRichText] BLOCKS.EMBEDDED_ASSET node.data.target.fields.file: ',
        //   node.data.target.fields.file
        // );
        if (
          !node ||
          !node.data.target.fields ||
          !node.data.target.fields.file
        ) {
          return null;
        }
        const file = extractLanguage(node.data.target.fields.file);
        // console.log('[renderRichText] BLOCKS.EMBEDDED_ASSET file: ', file);
        return (
          <>
            {file.contentType.startsWith('image') ? (
              <LazyLoadImage
                alt={node.data.target.fields.description}
                className={classNames(classes.embedTitleAsset)}
                src={file.url}
              />
            ) : null}
            {file.contentType.startsWith('video') ? (
              <video
                alt={node.data.target.fields.description}
                className={classes.embedTitleAsset}
                src={`${file.url}`}
              />
            ) : null}
          </>
        );
      },
      [INLINES.EMBEDDED_ENTRY]: node => {
        // console.log('[renderRichText] BLOCKS.INLINE_ENTRY node: ', node);
        // console.log(
        //   '[renderRichText] node.data.target.sys.contentType.sys.id.charAt(0).toUpperCase(): ',
        //   capitalizeFirstLetter(node.data.target.sys.contentType.sys.id)
        // );
        if (typeof node.data.target.sys.contentType !== 'undefined') {
          const Entry =
            entries[
              capitalizeFirstLetter(node.data.target.sys.contentType.sys.id)
            ];
          // TODO: send props with language pre-extracted with extractLanguage()
          return Entry ? (
            <Entry {...extractLanguage(node.data.target.fields)} />
          ) : null;
        }
        return null;
      },
      [BLOCKS.QUOTE]: (node, children) => {
        // console.log('[renderRichText] BLOCKS.QUOTE children: ', children);
        return (
          <blockquote>
            {children && typeof children === 'object' && children.length > 1 ? (
              children.map(child =>
                typeof child === 'string'
                  ? child.split('\n').map((line, lineIdx) => (
                      <span key={`pline-${lineIdx}`}>
                        {lineIdx > 0 ? <br /> : null}
                        {line}
                      </span>
                    ))
                  : child
              )
            ) : children.length === 1 && children[0] === ' ' ? (
              <span>&nbsp;</span>
            ) : children.length === 1 && children[0] === '' ? (
              <span></span>
            ) : (
              <blockquote>{children}</blockquote>
            )}
          </blockquote>
        );
      },
      [BLOCKS.PARAGRAPH]: (node, children) => {
        // console.log('[renderRichText] BLOCKS.PARAGRAPH children: ', children);
        return (
          <>
            {children && typeof children === 'object' && children.length > 1 ? (
              children.map(child =>
                typeof child === 'string'
                  ? child.split('\n').map((line, lineIdx) => (
                      <span key={`pline-${lineIdx}`}>
                        {lineIdx > 0 ? <br /> : null}
                        {line}
                      </span>
                    ))
                  : child
              )
            ) : children.length === 1 && children[0] === ' ' ? (
              <span>&nbsp;</span>
            ) : children.length === 1 && children[0] === '' ? (
              <span></span>
            ) : (
              <Typography {...fullProps[BLOCKS.PARAGRAPH]}>
                {children}
              </Typography>
            )}
          </>
        );
      },
      [BLOCKS.HEADING_1]: (node, children) => {
        // console.log('[renderRichText] BLOCKS.HEADING_1 children: ', children);
        if (children && children.length > 1)
          return (
            <Typography {...fullProps[BLOCKS.HEADING_1]}>{children}</Typography>
          );
        else if (
          children &&
          children.length === 1 &&
          typeof children[0] === 'string'
        ) {
          return (
            <Typography {...fullProps[BLOCKS.HEADING_1]}>{children}</Typography>
          );
        }
        // console.log(
        //   '[renderRichText] BLOCKS.HEADING_1 returning children: ',
        //   children
        // );
        return children;
      },
      [BLOCKS.HEADING_2]: (node, children) => {
        // console.log('[renderRichText] BLOCKS.HEADING_2 children: ', children);
        if (children && children.length > 1)
          return (
            <Typography {...fullProps[BLOCKS.HEADING_3]}>{children}</Typography>
          );
        else if (
          children &&
          children.length === 1 &&
          typeof children[0] === 'string'
        ) {
          return (
            <Typography {...fullProps[BLOCKS.HEADING_2]}>{children}</Typography>
          );
        }
        return children;
      },
      [BLOCKS.HEADING_3]: (node, children) => {
        // console.log('[renderRichText] BLOCKS.HEADING_3 children: ', children);
        if (children && children.length > 1)
          return (
            <Typography {...fullProps[BLOCKS.HEADING_3]}>{children}</Typography>
          );
        else if (
          children &&
          children.length === 1 &&
          typeof children[0] === 'string'
        ) {
          return (
            <Typography {...fullProps[BLOCKS.HEADING_3]}>{children}</Typography>
          );
        }
        return children;
      },
      [BLOCKS.HEADING_4]: (node, children) => {
        // console.log('[renderRichText] BLOCKS.HEADING_4 children: ', children);
        if (children && children.length > 1)
          return (
            <Typography {...fullProps[BLOCKS.HEADING_3]}>{children}</Typography>
          );
        else if (
          children &&
          children.length === 1 &&
          typeof children[0] === 'string'
        ) {
          return (
            <Typography {...fullProps[BLOCKS.HEADING_4]}>{children}</Typography>
          );
        }
        return children;
      },
      [BLOCKS.HEADING_5]: (node, children) => {
        // console.log('[renderRichText] BLOCKS.HEADING_5 children: ', children);
        if (children && children.length > 1)
          return (
            <Typography {...fullProps[BLOCKS.HEADING_3]}>{children}</Typography>
          );
        else if (
          children &&
          children.length === 1 &&
          typeof children[0] === 'string'
        ) {
          return (
            <Typography {...fullProps[BLOCKS.HEADING_5]}>{children}</Typography>
          );
        }
        return children;
      },
      [BLOCKS.HEADING_6]: (node, children) => {
        // console.log('[renderRichText] BLOCKS.HEADING_6 children: ', children);
        if (children && children.length > 1)
          return (
            <Typography {...fullProps[BLOCKS.HEADING_3]}>{children}</Typography>
          );
        else if (
          children &&
          children.length === 1 &&
          typeof children[0] === 'string'
        ) {
          return (
            <Typography {...fullProps[BLOCKS.HEADING_6]}>{children}</Typography>
          );
        }
        return children;
      }
    }
  });
};

export { renderText, renderRichText };
