/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
// @flow
import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import Grid from '@material-ui/core/Grid';

import { renderRichText } from '../utils/renderUtils';
import useSiteMetadata from '../utils/use-site-metadata';
import { makeStyles } from '@material-ui/core/styles';
import { styles } from './ContentfulOtherLinksPerTagSection.Style';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core';
import type { contentfulRichText } from '../utils/types';

type Props = {};

const useStyles = makeStyles(styles);

const ContentfulOtherLinksPerTagSection = (props: Props) => {
  const classes = useStyles();
  const {
    contentBeforeLinks,
    tagToLink,
    howtoPages,
    toolsPages,
    caseStudyPages,
    location,
    alignSection
  } = props;
  const tagListsMap = {
    Tools: toolsPages,
    'How To': howtoPages,
    'Case Study': caseStudyPages
  };
  const siteMetadata = useSiteMetadata();
  const [isLoaded, setIsLoaded] = useState(false);
  console.log('[ContentfulOtherLinksPerTagSection] props: ', props);
  console.log('[ContentfulOtherLinksPerTagSection] location', location);
  console.log('[ContentfulOtherLinksPerTagSection] siteMetadata', siteMetadata);

  const [tagItems, setTagItems] = useState([]);
  useEffect(() => {
    if (
      !isLoaded &&
      tagListsMap &&
      tagListsMap[tagToLink] &&
      tagListsMap[tagToLink].edges
    ) {
      setTagItems(
        tagListsMap[tagToLink].edges.filter(
          edge => location.pathname !== `/${edge.node.slug}`
        )
      );
      setIsLoaded(true);
    }
  }, tagListsMap);

  const RenderBigLinks = () => {
    return (
      <Grid
        className={classNames(
          classes.contentCtr,
          alignSection,
          classes.toolsSectionCtr,
          classes.bigLinksCtr
        )}
        item
        xs={12}
      >
        {tagItems.map((howtoPage, index) => (
          <>
            {index < 3 &&
            location &&
            location.pathname !== `/${howtoPage.node.slug}` ? (
              <a
                href={`/${howtoPage.node.slug}`}
                className={classNames(
                  classes.toolCtr,
                  index < 3 ? classes.bigLink : classes.smallLink
                )}
                key={`otherPages-${index}`}
              >
                <div>
                  {howtoPage.node.metaTags
                    ? howtoPage.node.metaTags.ogtitle
                    : 'Surprise AskNeo Page'}
                </div>
              </a>
            ) : null}
          </>
        ))}
      </Grid>
    );
  };
  const RenderSmallLinks = () => {
    return (
      <Grid
        className={classNames(
          classes.contentCtr,
          alignSection,
          classes.toolsSectionCtr,
          classes.smallLinksCtr
        )}
        item
        xs={12}
      >
        {tagListsMap &&
          tagListsMap[tagToLink] &&
          tagListsMap[tagToLink].edges.map((toolsPage, index) => (
            <>
              {index > 3 &&
              location &&
              location.pathname !== `/${toolsPage.node.slug}` ? (
                <a
                  href={`/${toolsPage.node.slug}`}
                  className={classNames(
                    classes.toolCtr,
                    index <= 3 ? classes.bigLink : classes.smallLink
                  )}
                  key={`otherPages-${index}`}
                >
                  <div>
                    {toolsPage.node.metaTags
                      ? toolsPage.node.metaTags.ogtitle
                      : 'Surprise AskNeo Tool'}
                  </div>
                </a>
              ) : null}
            </>
          ))}
      </Grid>
    );
  };

  return (
    <Grid className={classNames(classes.container)} item xs={12}>
      <Grid container direction='column'>
        <Grid
          className={classNames(
            classes.contentCtr,
            alignSection,
            classes.topContentCtr
          )}
          item
          xs={12}
        >
          {contentBeforeLinks ? renderRichText(contentBeforeLinks.json) : null}
        </Grid>

        <RenderBigLinks />
        <RenderSmallLinks />
      </Grid>
    </Grid>
  );
};

export default ContentfulOtherLinksPerTagSection;

// TODO: Move this to its own component "Tools" and reference to the graphql spread here. Create a "Tools Section" model in contentful
export const query = graphql`
  fragment ContentfulOtherLinksPerTagSection on ContentfulOtherLinksPerTagSection {
    entryTitle
    contentBeforeLinks {
      json
    }
    tagToLink
    alignSection
  }
`;
