export const styles = theme => {
  return {
    container: {
      backgroundColor: '#EAEAEA',
      ...theme.squareplan.footer.height,
      padding: `${theme.spacing(3.5)}px 0px ${theme.spacing(3)}px 0px`
    },
    contentCtr: {
      alignContent: 'start',
      margin: '0 auto',
      fontSize: theme.squareplan.footer.fontSize,
      ...theme.squareplan.window.width,
      '& p': {
        fontSize: theme.squareplan.footer.fontSize
      },
      '& > div': {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
      },
      // '& > div:nth-child(2)': {
      //   [theme.breakpoints.up('sm')]: {
      //     maxWidth: '28.666667%',
      //     flexBasis: '30.666667%'
      //   }
      // },
      // '& > div:last-child': {
      //   marginTop: 0,
      //   [theme.breakpoints.up('sm')]: {
      //     maxWidth: '33.666667%',
      //     flexBasis: '33.666667%'
      //   }
      // },
      [theme.breakpoints.up('xs')]: {
        alignContent: 'center'
      }
    },
    col1: {
      '& img': {
        maxWidth: '90%'
      },
      '& h3, & p': {
        marginTop: theme.spacing(2),
        fontWeight: 400,
        fontSize: theme.squareplan.footer.fontSize
      },
      // '& h3': {
      //   fontWeight: 600,
      //   fontSize: '1.1rem'
      // },
      [theme.breakpoints.up('md')]: {
        paddingRight: theme.spacing(1)
      }
    },
    rightColCtr: {
      height: '100%'
    },
    logoImg: {
      // position: 'relative',
      // top: -5
    },
    linksCtr: {
      '& > div': {
        marginBottom: theme.spacing(1.5),
        marginRight: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
          marginLeft: 0,
          marginRight: 0
        }
      }
    },
    socialLinksCtr: {
      marginTop: theme.spacing(3),
      fontSize: '1.2rem',
      '& > div:first-child': {
        marginLeft: 0
      },
      '& > div:last-child': {
        marginRight: 0
      },
      '& > div': {
        marginRight: theme.spacing(1)
      },
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'center'
      }
    },
    rights: {
      fontSize: '0.75rem'
    },
    copyright: {
      fontWeight: 300,
      fontSize: '1.4rem',
      position: 'relative',
      top: 7,
      lineHeight: '1em'
    },
    columnsCtr: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        display: 'block'
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: 0
      }
    },
    footerColumns: {
      padding: `0px ${theme.spacing(1)}px`,
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        padding: 0
      },
      [theme.breakpoints.down('xs')]: {
        width: '100% !important'
      }
    }
  };
};
