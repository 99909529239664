/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
// @flow
import React from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Grid from '@material-ui/core/Grid';

import SPButton from './SPButton';
import { makeStyles } from '@material-ui/core/styles';
import { styles } from './Contentful3ColumnSection.Style';
import { Typography } from '@material-ui/core';

import { renderRichText } from '../utils/renderUtils';
import type { contentfulRichText, contentfulImage } from '../utils/types';

type HeaderProps = {
  classes: Object,
  description: contentfulRichText,
  c3csTitle: contentfulRichText,
  columnDescription: contentfulRichText
};

const SectionHeader = (props: HeaderProps) => {
  const { classes, c3csTitle, description, alignSectionLeft } = props;
  console.log('[3col][Section Header]: ', props);
  return c3csTitle ? (
    <Grid
      item
      xs={12}
      className={classNames(
        alignSectionLeft ? classes.alignLeft : classes.centerText
      )}
    >
      <div className={classes.titleCtr}>
        {c3csTitle ? renderRichText(c3csTitle.json) : null}
      </div>
      <div className={classes.sectionDescCtr}>
        {description ? renderRichText(description.json) : null}
      </div>
    </Grid>
  ) : null;
};

type SectionProps = {
  classes: Object,
  sixItemSection: boolean,
  centerSectionsContent: boolean,
  title1: contentfulRichText,
  title2: contentfulRichText,
  title3: contentfulRichText,
  title4: contentfulRichText,
  title5: contentfulRichText,
  title6: contentfulRichText,
  description1: contentfulRichText,
  description2: contentfulRichText,
  description3: contentfulRichText,
  description4: contentfulRichText,
  description5: contentfulRichText,
  description6: contentfulRichText,
  image1: contentfulImage,
  image2: contentfulImage,
  image3: contentfulImage,
  image4: contentfulImage,
  image5: contentfulImage,
  image6: contentfulImage
};

const Section1 = (props: SectionProps) => {
  const {
    classes,
    title1,
    description1,
    image1,
    centerSectionsContent
  } = props;

  return (
    <Grid
      item
      xs={12}
      sm={4}
      className={classNames(
        classes.sectionCtr,
        classes.leftSideSection,
        centerSectionsContent ? classes.centerContent : null
      )}
    >
      <div>
        <div className={classes.titleCtr}>
          {image1 ? (
            <LazyLoadImage
              className={classNames('leftImage', classes.img)}
              src={image1.file.url}
              alt={image1.description}
            />
          ) : null}
          {title1 ? renderRichText(title1.json) : null}
        </div>
        <div className={classes.description}>
          {description1 ? <>{renderRichText(description1.json)}</> : null}
        </div>
      </div>
    </Grid>
  );
};

const Section2 = (props: SectionProps) => {
  const {
    classes,
    title2,
    description2,
    image2,
    centerSectionsContent
  } = props;

  return (
    <Grid
      item
      xs={12}
      sm={4}
      className={classNames(
        classes.sectionCtr,
        centerSectionsContent ? classes.centerContent : null
      )}
    >
      <div>
        <div className={classes.titleCtr}>
          {image2 ? (
            <LazyLoadImage
              className={classNames('leftImage', classes.img)}
              src={image2.file.url}
              alt={image2.description}
            />
          ) : null}
          {title2 ? renderRichText(title2.json) : null}
        </div>
        <div className={classes.description}>
          {description2 ? <>{renderRichText(description2.json)}</> : null}
        </div>
      </div>
    </Grid>
  );
};

const Section3 = (props: SectionProps) => {
  const {
    classes,
    title3,
    description3,
    image3,
    centerSectionsContent
  } = props;
  console.log(
    '[Section3] title3, description3, image3: ',
    title3,
    description3,
    image3
  );

  return (
    <Grid
      item
      xs={12}
      sm={4}
      className={classNames(
        classes.sectionCtr,
        classes.rightSideSection,
        centerSectionsContent ? classes.centerContent : null
      )}
    >
      <div>
        <div className={classes.titleCtr}>
          {image3 ? (
            <LazyLoadImage
              className={classNames('leftImage', classes.img)}
              src={image3.file.url}
              alt={image3.description}
            />
          ) : null}
          {title3 ? renderRichText(title3.json) : null}
        </div>
        <div className={(classes.column3, classes.description)}>
          {description3 ? <>{renderRichText(description3.json)}</> : null}
        </div>
      </div>
    </Grid>
  );
};

const Section4 = (props: SectionProps) => {
  const { classes, title4, description4, centerSectionsContent } = props;

  return (
    <Grid
      item
      xs={12}
      sm={4}
      className={classNames(
        classes.sectionCtr,
        classes.leftSideSection,
        centerSectionsContent ? classes.centerContent : null
      )}
    >
      <div>
        <div className={classes.titleCtr}>
          {title4 ? renderRichText(title4.json) : null}
        </div>
        <div className={classes.description}>
          {description4 ? <>{renderRichText(description4.json)}</> : null}
        </div>
      </div>
    </Grid>
  );
};

const Section5 = (props: SectionProps) => {
  const { classes, title5, description5, centerSectionsContent } = props;

  return (
    <Grid
      item
      xs={12}
      sm={4}
      className={classNames(
        classes.sectionCtr,
        centerSectionsContent ? classes.centerContent : null
      )}
    >
      <div>
        <div className={classes.titleCtr}>
          {title5 ? renderRichText(title5.json) : null}
        </div>
        <div className={classes.description}>
          {description5 ? <>{renderRichText(description5.json)}</> : null}
        </div>
      </div>
    </Grid>
  );
};

const Section6 = (props: SectionProps) => {
  const { classes, title6, description6, centerSectionsContent } = props;

  return (
    <Grid
      item
      xs={12}
      sm={4}
      className={classNames(
        classes.sectionCtr,
        classes.rightSideSection,
        centerSectionsContent ? classes.centerContent : null
      )}
    >
      <div>
        <div className={classes.titleCtr}>
          {title6 ? renderRichText(title6.json) : null}
        </div>
        <div className={(classes.column3, classes.description)}>
          {description6 ? <>{renderRichText(description6.json)}</> : null}
        </div>
      </div>
    </Grid>
  );
};

const useStyles = makeStyles(styles);
const Contentful3ColumnSection = (props: SectionProps) => {
  const {
    sixItemSection,
    cta,
    alignSectionLeft,
    isTransparentBackground,
    centerSectionsContent,
    ctaFunctions,
    theme
  } = props;
  console.log('[Contentful3ColumnSection] props: ', props);
  const classes = useStyles();
  return (
    <div
      className={classNames(
        classes.container,
        isTransparentBackground
          ? classes.transparentBckgd
          : classes.gradientBckgd,
        theme ? classes[`background${theme.background}`] : null,
        theme ? classes[`text${theme.text}`] : null,
        theme ? classes[`h1${theme.h1}`] : null,
        theme ? classes[`h2${theme.h2}`] : null,
        theme ? classes[`h3${theme.h3}`] : null
      )}
    >
      <Grid className={classes.contentCtr} container direction='column'>
        <SectionHeader {...props} classes={classes} />
        <Grid item>
          <Grid container className={classes.firstRowCtr}>
            <Section1 {...props} classes={classes} />
            <Section2 {...props} classes={classes} />
            {props.title3 || props.description3 || props.image3 ? (
              <Section3 {...props} classes={classes} />
            ) : null}
          </Grid>
          {sixItemSection ? (
            <Grid container className={classes.rowSpacer}>
              <Section4 {...props} classes={classes} />
              <Section5 {...props} classes={classes} />
              <Section6 {...props} classes={classes} />
            </Grid>
          ) : null}
        </Grid>
        {cta ? (
          <Grid
            classes={{
              root: classNames(
                classes.ctaCtr,
                alignSectionLeft ? classes.alignLeft : classes.centerText
              )
            }}
            item
            xs={12}
          >
            <SPButton
              center={alignSectionLeft ? false : true}
              {...cta}
              ctaFunctions={ctaFunctions}
            />
          </Grid>
        ) : null}
      </Grid>
    </div>
  );
};

export default Contentful3ColumnSection;

export const query = graphql`
  fragment Contentful3ColumnSection on Contentful3ColumnSection {
    theme {
      background
      text
      h1
      h2
      h3
    }
    isTransparentBackground
    centerSectionsContent
    alignSectionLeft
    sixItemSection
    c3csTitle: sectionTitle {
      json
    }
    description: sectionDescription {
      json
    }
    image1 {
      description
      file {
        url
        contentType
      }
    }
    image2 {
      description
      file {
        url
        contentType
      }
    }
    image3 {
      description
      file {
        url
        contentType
      }
    }
    title1: title1 {
      json
    }
    title2: title2 {
      json
    }
    title3: title3 {
      json
    }
    title4: title4 {
      json
    }
    title5: title5 {
      json
    }
    title6: title6 {
      json
    }
    description1: description1 {
      json
    }
    description2: description2 {
      json
    }
    description3: description3 {
      json
    }
    description4: description4 {
      json
    }
    description5: description5 {
      json
    }
    description6: description6 {
      json
    }
    cta {
      ...ContentfulButton
    }
  }
`;
