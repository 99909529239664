// @flow
/* eslint-disable react/no-multi-comp */
import React from 'react';
import { graphql } from 'gatsby';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { Typography } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import SPButton from './SPButton';
import { renderRichText } from '../utils/renderUtils';
import { styles } from './ContentfulHeroSection.Style';
const useStyles = makeStyles(styles);

import type {
  spButton,
  contentfulRichText,
  contentfulImage
} from '../utils/types';
import { call } from 'ramda';

type Props = {
  sectionTitle: contentfulRichText,
  subTitle: contentfulRichText,
  sectionSubTitle: contentfulRichText,
  callToActions: Array<spButton>,
  ctaFunctions: Object,
  image: contentfulImage // image
};

const SectionHero = (props: Props) => {
  const classes = useStyles();
  console.log('[SectionHero] props: ', props);
  const {
    sectionTitle,
    subTitle,
    image,
    callToActions,
    ctaCaption,
    ctaFunctions
  } = props;

  return (
    <Grid
      classes={{
        root: classes.container
      }}
      item
      xs={12}
    >
      <Grid className={classes.contentCtr} container direction='row'>
        <Grid
          item
          classes={{
            root: classes.leftTitlesCol
          }}
          sm={6}
          xs={12}
        >
          <div className={classes.title}>
            {sectionTitle ? renderRichText(sectionTitle.json) : null}
          </div>
          <div className={classes.subTitle}>
            {subTitle ? renderRichText(subTitle.json) : null}
          </div>
          <Hidden smUp>
            <div className={classes.heroImgMobileCtr}>
              <LazyLoadImage
                src={image.file.url}
                alt={image.description}
                className={classes.heroImg}
              />
            </div>
          </Hidden>
          <div className={classes.ctaBtnCtr}>
            {callToActions.map((callToAction, i) => (
              <SPButton
                {...callToAction}
                ctaFunctions={ctaFunctions}
                customClasses={{
                  button: classes.ctaBtn
                }}
                key={`cta-${i}`}
              />
            ))}
          </div>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Hidden xsDown>
            <LazyLoadImage
              src={image.file.url}
              alt={image.description}
              className={classes.heroImg}
            />
          </Hidden>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SectionHero;

export const query = graphql`
  fragment ContentfulHeroSection on ContentfulHeroSection {
    sectionTitle {
      json
    }
    subTitle {
      json
    }
    image {
      description
      file {
        url
        contentType
      }
    }
    callToActions {
      ...ContentfulButton
    }
  }
`;
