export const styles = theme => {
  return {
    container: {
      flexGrow: 1,
      backgroundColor: 'transparent'
      // minHeight: 500
    },
    grandientBackground: {
      backgroundImage:
        'linear-gradient(180deg, rgba(234,234,234,0.23) 0%, #ffffff 100%)'
    },
    useCaseContainer: {
      ...theme.squareplan.window.width,
      margin: 'auto',
      flexGrow: 1,
      backgroundColor: 'transparent',
      minHeight: 500
    },
    contentCtr: {
      ...theme.squareplan.window.width,
      margin: '0 auto',
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(4),
      '& a': {
        [theme.breakpoints.down('xs')]: {
          display: 'inline-block'
        }
      }
    },
    alignLeft: {
      textAlign: 'left'
    },
    centerText: {
      textAlign: 'center',
      margin: 'auto',
      '& a': {
        textAlign: 'center',
        display: 'inline-block'
      }
    },
    centerMobileOnly: {
      textAlign: 'left',
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center'
      }
    },
    contentCtrSm: {
      ...theme.squareplan.window.width,
      margin: '0 auto',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(4),
      '& a': {
        [theme.breakpoints.down('xs')]: {
          textAlign: 'center',
          display: 'block'
        }
      }
    },
    caseStudy: {
      ...theme.squareplan.window.width,
      margin: 'auto',
      marginBottom: theme.spacing(6),
      marginTop: 0,
      padding: theme.spacing(6),
      background: 'rgba(243, 241, 239, 1)',
      borderRadius: theme.spacing(6)
    },
    content: {
      fontSize: theme.spacing(2.1),
      lineHeight: 1.5
    },
    author: {
      marginLeft: theme.spacing(2),
      fontWeight: 800,
      fontSize: theme.spacing(2.1),
      [theme.breakpoints.up('sm')]: {
        lineHeight: `${theme.spacing(5)}px`
      },
      [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.spacing(2),
        display: 'table-cell',
        verticalAlign: 'middle'
      }
    },
    authorDetails: {
      display: 'flex',
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down('xs')]: {
        display: 'table'
      }
    },
    avatar: {
      width: theme.spacing(5),
      height: theme.spacing(5)
    },
    contentCtrLanding: {
      ...theme.squareplan.window.width,
      margin: '0 auto',
      paddingTop: theme.spacing(9),
      paddingBottom: theme.spacing(9),
      '& a': {
        [theme.breakpoints.down('xs')]: {
          textAlign: 'center',
          display: 'block'
        }
      }
    },
    textCtr: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'left',
      // justifyContent: 'space-around',
      [theme.breakpoints.down('xs')]: {
        order: 1
      },
      [theme.breakpoints.up('lg')]: {
        '& p, h6': {
          lineHeight: 1.5
        }
      }
    },
    textCtrUseCase: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'left',
      [theme.breakpoints.down('xs')]: {
        order: 1
      },
      [theme.breakpoints.up('lg')]: {
        '& p': {
          marginTop: theme.spacing(4),
          marginBottom: theme.spacing(4),
          lineHeight: 1.5
        }
      }
    },
    titleCtr: {
      position: 'relative',
      display: 'block',
      justifyContent: 'flex-start',
      alignItems: 'center',
      width: '100%',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(0),
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(2)
      }
    },
    fullWidthTextTop: {
      ...theme.squareplan.window.width,
      margin: 'auto',
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(6)
    },
    title: {
      display: 'inline'
    },
    // titleCtrSmall: {
    //   '& h2, p': {
    //     fontSize: theme.spacing(3)
    //   }
    // },
    // landingTxt: {
    //   // fontSize: theme.spacing(2.1),
    //   marginTop: theme.spacing(2),
    //   marginBottom: theme.spacing(2),
    //   '& h6': {
    //     fontWeight: 400
    //   }
    // },
    description: {
      // marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      paddingRight: theme.spacing(8),
      '& h6': {
        fontWeight: 400
      },
      [theme.breakpoints.down('sm')]: {
        paddingRight: theme.spacing(4)
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        paddingRight: 0
      }
    },
    embedTitleAsset: {
      position: 'relative',
      bottom: -4,
      display: 'inline',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '2rem',
      height: '2rem'
    },
    rightCol: {
      paddingLeft: theme.spacing(1),
      textAlign: 'left',
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        paddingRight: theme.spacing(4),
        paddingLeft: theme.spacing(4)
      }
    },
    leftCol: {
      paddingRight: theme.spacing(1),
      textAlign: 'left',
      display: 'flex',
      '& img.leftImage': {
        marginLeft: 0
      },
      [theme.breakpoints.down('xs')]: {
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        '& img.leftImage': {
          paddingRight: 0,
          paddingLeft: 0,
          marginLeft: 'auto',
          marginRight: 'auto'
        }
      }
    },
    videoCtr: {
      position: 'relative',
      overflow: 'hidden',
      paddingTop: '56.25%',
      width: '100%'
    },
    videoClass: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      border: 0
    },
    imgCtr: {
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        order: 2,
        margin: '0 auto',
        marginTop: theme.spacing(2)
      }
    },
    imgCtrTop: {
      alignItems: 'end',
      [theme.breakpoints.down('xs')]: {
        order: 2,
        margin: '0 auto',
        marginTop: theme.spacing(2),
        textAlign: 'center',
        justifyContent: 'center',
        paddingRight: 0,
        paddingLeft: 0
      }
    },
    img: {
      // filter: 'drop-shadow(2px 2px 6px hsl(0,0%,0%,60%))',
      maxWidth: '80%',
      margin: '0 auto',
      [theme.breakpoints.down('xs')]: {
        maxWidth: '80%'
      }
    },
    imgCaseStudy: {
      // filter: 'drop-shadow(2px 2px 6px hsl(0,0%,0%,60%))',
      maxWidth: '80%',
      margin: '0 auto',
      // borderRadius: theme.spacing(1.25),
      [theme.breakpoints.up('sm')]: {
        marginRight: 0
      },
      [theme.breakpoints.down('xs')]: {
        maxWidth: '100%',
        margin: 0
      }
    },
    ctaCtr: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center'
      }
    },
    footerBtnCtr: {
      marginBottom: theme.spacing(4)
    },
    ...theme.contentfulThemes
  };
};
