import React from 'react';
import * as R from 'ramda';
import { graphql } from 'gatsby';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { styles } from './ContentfulHtmlSnippet.Style';
import theme from '../theme';
import type { contentfulImage } from '../utils/types';
import { renderRichText } from '../utils/renderUtils';

type Props = {
  language: {
    en: Array<string>
  }
};

const ContentfulHtmlSnippet = (props: Props) => {
  console.log('[ContentfulHtmlSnippet] props: ', props);
  const { content } = props;
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  return (
    <>
      {content ? (
        <div
          dangerouslySetInnerHTML={{
            __html: content
          }}
        />
      ) : null}
    </>
  );
};

export default ContentfulHtmlSnippet;

export const query = graphql`
  fragment ContentfulHtmlSnippet on ContentfulHtmlSnippet {
    content {
      json
    }
  }
`;
