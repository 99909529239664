import React from 'react';
import * as R from 'ramda';
import { graphql } from 'gatsby';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';

import { makeStyles } from '@material-ui/core/styles';
import { styles } from './ContentfulCarouselSection.Style';
import ContentfulRichImage from './ContentfulRichImage';
import theme from '../theme';
import type { contentfulImage } from '../utils/types';
import { renderRichText } from '../utils/renderUtils';

type Props = {
  title: string,
  subtitle: string,
  sectionIdx: number,
  testimonialContent: Object
};

const carouselItems = {
  ContentfulRichImage
};

const ContentfulCarouselSection = (props: Props) => {
  console.log('[ContentfulCarouselSection] props: ', props);
  const { items, itemsNbToDisplay, topContent, sectionIdx } = props;
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 2500,
    easing: 'easeCubic',
    slidesToShow: itemsNbToDisplay || 4
  };

  const settingsMobile = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 2500,
    easing: 'easeCubic',
    slidesToShow: itemsNbToDisplay > 3 ? 3 : itemsNbToDisplay || 3
  };

  return (
    <Grid
      classes={{
        root: classes.container
      }}
      item
      xs={12}
    >
      <div className={classes.topContent}>
        {topContent ? renderRichText(topContent.json) : null}
      </div>
      <Hidden xsDown>
        <div className={classes.carouselCtr}>
          {items && items.length > 0 ? (
            <Slider {...settings} className='overflow-hidden'>
              {items.map((itemProps, index) => (
                <Grid
                  item
                  key={`col2section-${index}`}
                  xs={12}
                  className={classes.carouselItemCtr}
                >
                  {itemProps && itemProps.image && itemProps.image.file.url ? (
                    <ContentfulRichImage {...itemProps} />
                  ) : null}
                </Grid>
              ))}
            </Slider>
          ) : null}
        </div>
      </Hidden>
      <Hidden smUp>
        {items && items.length > 0 ? (
          <div className={classes.sliderCtr}>
            <Slider {...settingsMobile} className='overflow-hidden'>
              {items.map((itemProps, index) => (
                <div
                  key={`col2section-${index}`}
                  className={classes.carouselItemCtr}
                >
                  {itemProps && itemProps.image && itemProps.image.file.url ? (
                    <ContentfulRichImage {...itemProps} />
                  ) : null}
                </div>
              ))}
            </Slider>
          </div>
        ) : null}
      </Hidden>
    </Grid>
  );
};

export default ContentfulCarouselSection;

export const query = graphql`
  fragment ContentfulCarouselSection on ContentfulCarouselSection {
    topContent {
      json
    }
    entryTitle
    itemsNbToDisplay
    items {
      ...ContentfulRichImage
    }
  }
`;
