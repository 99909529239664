/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
// @flow
import React from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import Grid from '@material-ui/core/Grid';

import { renderRichText } from '../utils/renderUtils';
import useSiteMetadata from '../utils/use-site-metadata';
import { makeStyles } from '@material-ui/core/styles';
import { styles } from './ContentfulFooterAutoTagList.Style';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core';
import type { contentfulRichText } from '../utils/types';

type Props = {
  tag: string
};

const useStyles = makeStyles(styles);

const ContentfulFooterAutoTagList = (props: Props) => {
  const classes = useStyles();
  const {
    tag,
    listTitle,
    generalPages,
    howtoPages,
    toolsPages,
    caseStudyPages
  } = props;
  const siteMetadata = useSiteMetadata();
  console.log('[ContentfulFooterAutoTagList] props: ', props);
  console.log('[ContentfulFooterAutoTagList] siteMetadata', siteMetadata);

  const RenderRichLinks = () => {
    return (
      <>
        <div className={classes.tagTitle}>
          {listTitle && listTitle.json !== '' ? (
            renderRichText(listTitle.json)
          ) : (
            <Typography variant='h4' component='p'>
              {tag}
            </Typography>
          )}
        </div>

        {tag === 'How To' &&
          howtoPages &&
          howtoPages.edges.map((howtoPage, index) => (
            <div key={`t-${index}`} className={classNames(classes.tagCtr)}>
              <a href={`${howtoPage.node.slug}`} key={`case-study-${index}`}>
                {howtoPage.node.metaTags
                  ? howtoPage.node.metaTags.ogtitle
                  : 'Surprise AskNeo Case Study'}
              </a>
            </div>
          ))}
        {tag === 'General' &&
          generalPages &&
          generalPages.edges.map((generalPage, index) => (
            <div key={`t-${index}`} className={classNames(classes.tagCtr)}>
              <a href={`${generalPage.node.slug}`} key={`generalPage-${index}`}>
                {generalPage.node.metaTags
                  ? generalPage.node.metaTags.ogtitle
                  : 'Surprise Page!'}
              </a>
            </div>
          ))}
        {tag === 'Tools' &&
          toolsPages &&
          toolsPages.edges.map((toolsPage, index) => (
            <div key={`t-${index}`} className={classNames(classes.tagCtr)}>
              <a href={`${toolsPage.node.slug}`} key={`toolsPages-${index}`}>
                {toolsPage.node.metaTags
                  ? toolsPage.node.metaTags.ogtitle
                  : 'Surprise AskNeo Tool'}
              </a>
            </div>
          ))}
        {tag === 'Case Study' &&
          caseStudyPages &&
          caseStudyPages.edges.map((caseStudyPage, index) => (
            <div key={`t-${index}`} className={classNames(classes.tagCtr)}>
              <a
                href={`${caseStudyPage.node.slug}`}
                key={`case-study-${index}`}
              >
                {caseStudyPage.node.metaTags
                  ? caseStudyPage.node.metaTags.ogtitle
                  : 'Surprise AskNeo Case Study'}
              </a>
            </div>
          ))}
      </>
    );
  };

  return (
    <Grid className={classNames(classes.container)} item xs={12}>
      <Grid container direction='column'>
        <RenderRichLinks />
      </Grid>
    </Grid>
  );
};

export default ContentfulFooterAutoTagList;

// TODO: Move this to its own component "Tools" and reference to the graphql spread here. Create a "Tools Section" model in contentful
export const query = graphql`
  fragment ContentfulFooterAutoTagList on ContentfulFooterAutoTagList {
    tag
    listTitle {
      json
    }
  }
`;
