import { Block } from '@material-ui/icons';
import { window, document } from 'browser-monads';

export const styles = theme => ({
  container: {
    width: '90%',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '80%'
    }
  },
  sectionHeader: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(3)
  },
  accordionCtr: {
    marginTop: theme.spacing(1.5),
    borderRadius: 0,
    border: '1px solid lightgrey'
  },
  finalCtr: {
    marginTop: theme.spacing(1.5),
    borderRadius: 0,
    border: '1px solid lightgrey',
    marginBottom: theme.spacing(10)
  },
  title: {
    fontWeight: 600,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  anchorCtr: {
    position: 'relative',
    top: theme.spacing(-25)
  },
  details: {
    display: 'block',
    lineHeight: 1.7
  }
});
