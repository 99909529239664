// @flow
import React from 'react';

import Grid from '@material-ui/core/Grid';

import { makeStyles } from '@material-ui/core/styles';
import { styles } from './ContentfulPreFooterSection.Style';
import { Typography } from '@material-ui/core';
import { renderRichText } from '../utils/renderUtils';

import type { contentfulRichText } from '../utils/types';

type Props = {
  title: string,
  body: contentfulRichText,
  mainSection: boolean
};
const ContentfulPreFooterSection = (props: Props) => {
  // TODO: dict to inject from contentful
  const useStyles = makeStyles(
    styles({
      backgroundImage: 'url(/SquarePlan-Cover-web4-2019-01.png)'
    })
  );
  const classes = useStyles();
  const { title, body, mainSection } = props;

  // const renderBodyOptions = {
  //   renderNode: {
  //     // eslint-disable-next-line react/display-name
  //     [BLOCKS.PARAGRAPH]: (node, children) => (
  //       <Typography className={classes.subTitle} variant='body1'>
  //         {children}
  //       </Typography>
  //     ),
  //     [BLOCKS.EMBEDDED_ASSET]: node => {
  //       const assetType = node.data.target.fields.file.contentType;
  //       return assetType.includes('video') ? (
  //         <video
  //           className={classes.imagePreview}
  //           src={`${node.data.target.fields.file.url}`}
  //         />
  //       ) : (
  //         <img
  //           className={classes.imagePreview}
  //           src={`${node.data.target.fields.file.url}`}
  //         />
  //       );
  //     }
  //   }
  // };

  return (
    <Grid
      className={mainSection ? classes.containerMain : classes.container}
      container
    >
      {title || body ? (
        <Grid
          className={mainSection ? classes.textCtrMain : classes.textCtr}
          item
          xs={12}
        >
          <Typography variant='h2'>{title}</Typography>
          {body ? renderRichText(body.json) : null}
        </Grid>
      ) : (
        <Grid className={classes.emptyCtr} item xs={12} />
      )}
      {!mainSection ? (
        <Grid className={classes.bottomSpace} item xs={12} />
      ) : null}
    </Grid>
  );
};

export default ContentfulPreFooterSection;
