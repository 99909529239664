
export const styles = (images) => (theme) => {
  return {
    container: {
      textAlign: 'center'
    },
    containerMain: {
      textAlign: 'left',
      width: '70%',
      margin: 'auto'
    },
    textCtr: {
      paddingTop: theme.spacing(6),
      marginBottom: theme.spacing(13),
      color: theme.palette.text.disabled
    },
    textCtrMain: {
      paddingTop: theme.spacing(8),
      marginBottom: theme.spacing(13),
      '& h6': {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(1),
        fontSize: theme.spacing(3)
      },
      '& p': {
        lineHeight: `${theme.spacing(3.5)}px`,
        margin: `${theme.spacing(2)}px 0`
      },
      '& img': {
        width: '100%',
        marginTop: theme.spacing(4)
      }
    },
    emptyCtr: {
      height: theme.spacing(2)
    },
    bottomSpace: {
      height: 250,
      backgroundImage: images.backgroundImage,
      backgroundPosition: 'bottom center',
      backgroundSize: 'cover'
    }
  };
};