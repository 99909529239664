// @flow
/* eslint-disable react/no-multi-comp */
import React from 'react';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { graphql } from 'gatsby';

import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { Typography } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import SPButton from './SPButton';
import { styles } from './ContentfulKpIsSection.Style';
// import theme from '../theme';

import type { spButton } from '../utils/types';
import classNames from 'classnames';

type Props = {
  style: 'primary' | 'secondary' | 'tertiary',
  title: string,
  subtitle: string,
  ctaCaption: string,
  kpiBannerContent: Object, // JSON
  ckpisCta: spButton
};

const ContentfulKpIsSection = (props: Props) => {
  console.log('[ContentfulKpIsSection] props: ', props);
  const {
    style,
    title,
    subtitle,
    kpiBannerContent,
    ckpisCta,
    ctaFunctions,
    ctaCaption,
    theme
  } = props;
  // inject styles
  const useStyles = makeStyles(
    styles({
      // backgroundColor: classes[`background${theme.background}`],
      // kpi: {
      //   backgroundColor: classes[`background${theme.kpiBackground}`]
      // },
      kpiCol: {
        width: `${100 / kpiBannerContent.length}%`
      }
    })
  );
  const classes = useStyles();

  // custom of bold mark
  const renderSubTitleOptions = {
    renderNode: {
      // eslint-disable-next-line react/display-name
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Typography className={classes.subTitle} variant='body1'>
          {children}
        </Typography>
      )
    }
  };

  return (
    <Grid
      classes={{
        root: classes.container
      }}
      className={classNames(
        theme ? classes[`background${theme.background}`] : null
        // theme ? classes[`text${theme.text}`] : null,
        // theme ? classes[`h1${theme.h1}`] : null,
        // theme ? classes[`h2${theme.h2}`] : null,
        // theme ? classes[`h3${theme.h3}`] : null,
        // theme ? classes[`h4${theme.h4}`] : null
      )}
      item
      xs={12}
    >
      <Grid className={classes.contentCtr} container direction='row'>
        <Grid item xs={12}>
          <Typography
            className={classNames(
              classes.title,
              theme ? classes[`h2${theme.h2}`] : null
            )}
            variant='h2'
            component='p'
          >
            {title}
          </Typography>
          <Typography
            className={classNames(
              classes.subtitle,
              theme ? classes[`h4${theme.h4}`] : null
            )}
            variant='h4'
            component='p'
          >
            {subtitle}
          </Typography>
        </Grid>
        <div className={classes.kpiCtr}>
          {kpiBannerContent.map((kpi, kpiIdx) => (
            <div className={classes.kpiCol} key={`kpi-${kpiIdx}`}>
              <div
                className={classNames(
                  classes.kpi,
                  classes[`background${theme.kpiBackground}`]
                )}
              >
                <Typography
                  className={classNames(
                    classes.kpiTypo,
                    classes[`text${theme.kpiText}`]
                  )}
                  variant='h2'
                  component='p'
                >
                  {kpi.kpi}
                </Typography>
              </div>
              <div className={classNames(classes.label)}>
                {kpi.label.split('\n').map((line, lineIdx) => (
                  <Typography
                    className={classNames(
                      classes.labelTypo,
                      theme ? classes[`h4${theme.h4}`] : null
                    )}
                    key={`line-${kpiIdx}=${lineIdx}`}
                    variant='h4'
                    component='h4'
                  >
                    {line}
                  </Typography>
                ))}
              </div>
            </div>
          ))}
        </div>

        {ckpisCta ? (
          <Grid
            classes={{
              root: classNames(
                classes.ctaCtr,
                theme ? classes[`text${theme.text}`] : null
              )
            }}
            item
            xs={12}
          >
            <SPButton {...ckpisCta} ctaFunctions={ctaFunctions} />
            <Typography
              variant='body1'
              className={classNames(classes.ctaCaption)}
            >
              {ctaCaption}
            </Typography>
          </Grid>
        ) : null}
        {/* <Grid
          item
          xs={12}
          className={classes.subTitleCtr}
        >
          {
            subTitle
            ? documentToReactComponents(subTitle.json, renderSubTitleOptions)
            : null
          }
        </Grid> */}
      </Grid>
    </Grid>
  );
};

export default ContentfulKpIsSection;

export const query = graphql`
  fragment ContentfulKpIsSection on ContentfulKpIsSection {
    entryTitle
    style
    theme {
      background
      kpiBackground
      kpiText
      text
      h1
      h2
      h3
      h4
    }
    title
    subtitle
    ctaCaption
    kpiBannerContent: content {
      kpi
      label
    }
    ckpisCta: cta {
      style
      link {
        url
        label
        icon {
          description
          file {
            url
            contentType
          }
        }
        iconPosition
      }
    }
  }
`;
