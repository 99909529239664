import { window, document } from 'browser-monads';

const HERO_MAX_HEIGHT = 500;
export const styles = injectedStyles => theme => {
  console.log('injectedStyles: ', injectedStyles);
  return {
    container: {
      flexGrow: 1,
      position: 'relative',
      // height: window.innerHeight > 900
      //   ? `calc((var(--vh, 1vh) * 100 - ${theme.squareplan.header.height.height}px) * 0.62)`
      //   : 'auto',
      minHeight: HERO_MAX_HEIGHT,
      '& pre': theme.typography.pre,
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        height: 'auto'
      }
    },
    containerBackground: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'transparent',
      backgroundImage: injectedStyles.backgroundImage,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      opacity: 0.5,
      zIndex: -1
    },
    contentCtr: {
      margin: '0 auto',
      paddingTop: theme.spacing(18),
      paddingBottom: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(13)
      }
    },
    title: {
      color: theme.palette.text.primary,
      fontWeight: 800,
      fontSize: '3.5rem',
      width: '100%',
      textAlign: 'center',
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        fontSize: '2.8rem'
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(2)
      }
    },
    subTitleCtr: {
      backgroundColor: 'hsla(0, 0%, 100%, 0.85)',
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
    subTitle: {
      width: '100%',
      textAlign: 'center',
      fontSize: '1.5rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.1rem'
      }
    },
    heroImgMobileCtr: {
      width: '100%',
      textAlign: 'center'
    },
    heroImg: {
      position: 'absolute',
      right: 0,
      bottom: 0,
      height: '90%',
      [theme.breakpoints.down('md')]: {
        height: 'auto',
        // width: '50%',
        maxWidth: '50%',
        maxHeight: `calc(${HERO_MAX_HEIGHT}px * 0.9)`
        // marginBottom: `calc((var(--vh, 1vh) * 20 - ${theme.squareplan.header.height.height}px))`
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: `calc(${HERO_MAX_HEIGHT}px * 0.15)`
      },
      [theme.breakpoints.down('xs')]: {
        position: 'relative',
        left: 0,
        width: '75%',
        maxWidth: '100%',
        marginBottom: theme.spacing(2)
      }
    },
    ctaBtnCtr: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center'
      }
    },
    ctaBtn: {
      marginTop: theme.spacing(6),
      marginRight: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(1)
      },
      [theme.breakpoints.down('xs')]: {
        marginRight: 0
      }
    }
  };
};
