import { window, document } from 'browser-monads';

const drawerWidth = 240;
export const styles = (theme) => ({
  container: {
    display: 'inline'
  },
  drawer: {
    display: 'inline-grid',
    top: theme.spacing(43.75),
    fontSize: theme.spacing(2),
    height: '50%',
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
      zIndex: 1,
      background: 'none'
    },
    [theme.breakpoints.down('sm')]: {
      display:'none'
    },
    [theme.breakpoints.down('md')]: {
      height: '48%'
    },
    '& .hidden': {
      display: 'none'
    }
  },
  listItemCtr: {
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      fontSize: theme.spacing(1.625)
    },
    '& a.active': {
      borderRight: '3px solid',
      borderColor: 'indigo',
      backgroundColor: 'rgba(255,255,255, 0.5)'
    },
    '& a': {
      width: '100%',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    }
  }
});