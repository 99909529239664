export const styles = theme => ({
  container: {
    textDecoration: 'none',
    '&:hover, & a, & a:hover': {
      textDecoration: 'none !important'
    }
  },
  btn: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    padding: `${theme.spacing(1.3)}px ${theme.spacing(2.5)}px ${theme.spacing(
      1.5
    )}px ${theme.spacing(2.5)}px`,
    borderRadius: theme.spacing(4),
    whiteSpace: 'nowrap'
  },
  iconLeft: {
    marginRight: theme.spacing(1.5)
  },
  iconRight: {
    marginLeft: theme.spacing(1.5)
  },
  buttonCaption: {
    marginTop: theme.spacing(1),
    marginBottom: '8px !important',
    // fontWeight: 700,
    fontSize: '0.89rem',
    paddingLeft: theme.spacing(2),
    '&.center': {
      paddingLeft: 0
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
      textAlign: 'center'
    }
  }
});
