// @flow

export const styles = injectedStyles => (theme: Object) => ({
  container: {
    marginTop: theme.spacing(3),
    minHeight: 'calc(100vh - 600px)',
    display: 'flex',
    textAlign: 'center',
    '& a:hover': {
      textDecoration: 'none'
    }
  },
  yearlyToggle: {
    margin: '0 auto',
    marginBottom: theme.spacing(2),
    '& label': {
      flexWrap: 'nowrap'
    }
  },
  smallPaper: {
    minHeight: '0px !important',
    paddingTop: '0px !important',
    paddingBottom: '0px !important'
  },
  rechargesCtr: {
    ...theme.squareplan.window.width,
    margin: '0 auto',
    marginBottom: theme.spacing(6),
    '& a:hover': {
      textDecoration: 'none'
    }
  },
  gridPaper: {
    ...theme.squareplan.window.width,
    borderRadius: 0,
    margin: '0 auto',
    marginBottom: theme.spacing(3),
    minHeight: 200,
    paddingBottom: 0,
    '& h2': {
      fontSize: '2rem',
      color: theme.palette.primary.main,
      paddingBottom: 0
    }
  },
  hiddenSpace: {
    color: '#fff !important',
    pointerEvents: 'none'
  },
  featuresList: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      paddingBottom: theme.spacing(2),
      backgroundColor: 'transparent'
      // backgroundImage:
      //   'linear-gradient(180deg, rgba(234,234,234,0.23) 0%, #ffffff 100%)'
    }
  },
  featureHeader: {
    paddingTop: theme.spacing(4),
    width: '100%',
    height: 260,
    // borderBottom: `1px solid ${theme.palette.border.color}`,
    borderRight: `1px solid ${theme.palette.border.color}`,
    [theme.breakpoints.down('xs')]: {
      borderBottom: 0,
      borderRight: 0,
      height: 'auto'
    }
  },
  gridCell: {
    display: 'flex',
    maxHeight: 50,
    minHeight: 50,
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    justifyContent: 'center',
    alignItems: 'center',
    borderRight: `1px solid ${theme.palette.border.color}`,
    // borderBottom: `1px solid ${theme.palette.border.color}`,
    '&.bottom': {
      borderBottom: 0
    },
    [theme.breakpoints.down('xs')]: {
      borderBottom: 0,
      borderRight: 0,
      margin: `${theme.spacing(1)}px 0px`,
      maxHeight: 'auto',
      minHeight: 'auto'
    }
  },
  emphasis: {
    fontSize: '1rem !important',
    fontWeight: 700,
    paddingTop: theme.spacing(4.5)
  },
  emphasisDescr: {
    fontWeight: 600,
    fontSize: '16px !important',
    paddingTop: `${theme.spacing(4.5)}px !important`
  },
  featureDesc: {
    padding: `0px ${theme.spacing(2)}px`,
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    fontSize: '0.9rem',
    textAlign: 'left',
    justifyContent: 'left',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      justifyContent: 'center',
      marginTop: theme.spacing(2.5),
      fontSize: theme.spacing(3.25),
      paddingTop: 0
    }
  },
  featureItem: {
    fontSize: '0.9rem',
    textAlign: 'center',
    '& pre': theme.typography.pre
  },
  checkIcon: {
    color: theme.palette.primary.main,
    fontSize: '1.1rem'
  },
  buy: {
    marginTop: theme.spacing(2)
  },
  topBuy: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0),
    borderRadius: theme.spacing(3),
    maxWidth: '90%',
    fontSize: '0.8rem',
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(1.75)
    },
    [theme.breakpoints.only('sm')]: {
      marginTop: theme.spacing(1.25),
      fontSize: '0.8rem'
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1)
    }
  },
  buttonCaption: {
    fontSize: '0.9rem',
    color: theme.palette.text.disabled
  },
  mobileOnlyPricingGridHOCtr: {
    width: '100%',
    margin: 0,
    display: 'flex',
    flexDirection: 'column'
  },
  mobileOnlyPricingGridContainer: {
    ...theme.squareplan.window.width,
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  tableCaptionCtr: {
    marginBottom: theme.spacing(6)
  },
  bottomText: {
    textAlign: 'center',
    '& p': {
      margin: 0,
      lineHeight: 1.5
    },
    marginBottom: theme.spacing(8)
  },
  topUpTable: {
    width: '50%'
  },
  title: {
    color: theme.palette.custom.neoViolet.main
  },
  planTitle: {
    fontSize: '1.5rem !important',
    marginBottom: theme.spacing(1)
  },
  ctaCaption: {
    color: '#7A7A7A',
    fontSize: '0.9rem',
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(0.5)
    }
  },
  planPrice: {
    fontSize: '1.2rem',
    fontWeight: 600
  },
  striked: {
    textDecoration: 'line-through',
    fontSize: '0.9rem',
    fontWeight: 400,
    marginTop: 0,
    color: theme.palette.text.disabled
  },
  rechargesTitleCtr: {
    textAlign: 'center',
    marginBottom: theme.spacing(4)
  },
  creditItemCtr: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: -theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginBottom: -theme.spacing(2)
    }
  },
  itemBodyLeft: {
    flexGrow: 1
  },
  itemBodyRight: {},
  itemBodyCtr: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  itemFooter: {
    flexGrow: 1,
    borderBottom: '3px dashed hsl(0,0%,50%)'
  },
  topupCredits: {
    fontSize: '1.5rem',
    paddingBottom: theme.spacing(0),
    fontWeight: 600
  },
  topupCaption: {
    fontSize: '0.9rem',
    fontWeight: 500,
    color: 'hsl(0,0%,50%)',
    paddingBottom: theme.spacing(0)
  },
  topupPrice: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    borderRadius: 24,
    position: 'relative',
    top: '8px',
    padding: '2px 16px',
    fontWeight: 600,
    fontSize: '1rem'
  },
  planPricePAYG: {
    marginTop: 12,
    fontWeight: 600,
    fontSize: '1rem'
  },
  newCenteredSectionCtr: {
    margin: '0 auto',
    textAlign: 'center'
  },
  topMarginSpacing2: {
    marginTop: theme.spacing(2)
  },
  topMarginSpacing4: {
    marginTop: theme.spacing(4)
  },
  topMarginSpacing6: {
    marginTop: theme.spacing(6)
  },
  bottomMarginSpacing2: {
    marginBottom: theme.spacing(2)
  },
  bottomMarginSpacing6: {
    marginBottom: theme.spacing(6)
  },
  topupSignupBtn: {
    margin: '0 auto',
    borderRadius: theme.spacing(3),
    '& *': {
      fontSize: '1rem'
    }
  },
  smallh5: {
    fontWeight: 600,
    fontSize: '1.1rem',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  smallBody1: {
    fontSize: '0.9rem'
  },
  bottomText: {
    textAlign: 'center',
    '& p': {
      margin: 0,
      lineHeight: 1.5
    },
    marginBottom: theme.spacing(8)
  },
  newCenteredSectionCtr: {
    margin: '0 auto',
    textAlign: 'center'
  }
});
