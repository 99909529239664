import React from 'react';
import classNames from 'classnames';
import { graphql } from 'gatsby';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { styles } from './ContentfulRichImage.Style';
import theme from '../theme';
import { extractNestedFields } from '../utils/stringUtils';
import type { contentfulImage } from '../utils/types';

type Props = {
  title: string,
  subtitle: string,
  width: string,
  testimonialContent: Object
};

const ContentfulRichImage = (props: Props) => {
  console.log('[ContentfulRichImage] props: ', props);
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const { width, image, url } = props;
  const imageProps = extractNestedFields(image);
  console.log('[ContentfulRichImage] imageProps: ', imageProps);
  return (
    <>
      {url && imageProps && imageProps.file ? (
        <a href={url} target='_blank'>
          <img
            className={classNames(classes.imageProps, classes.img)}
            src={imageProps.file.url}
            width={width ? width : '180px'}
            height='auto'
          />
        </a>
      ) : null}
      {!url && imageProps && imageProps.file ? (
        <img
          className={classNames(classes.imageProps, classes.img)}
          src={imageProps.file.url}
          width={width ? width : '180px'}
          height='auto'
        />
      ) : null}
    </>
  );
};

export default ContentfulRichImage;

export const query = graphql`
  fragment ContentfulRichImage on ContentfulRichImage {
    entryTitle
    image {
      file {
        url
        contentType
      }
    }
    url
    width
  }
`;
