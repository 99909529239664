export const styles = theme => {
  return {
    root: {
      flexGrow: 1,
      backgroundColor: '#fffffff2',
      boxShadow: 'none'
    },
    toolbarRoot: {
      margin: '0 auto',
      ...theme.squareplan.header.height,
      ...theme.squareplan.window.width,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        justifyContent: 'space-between'
      }
    },
    menuButton: {
      marginRight: theme.spacing(2)
    },
    ctaBtn: {
      marginRight: theme.spacing(3)
    },
    title: {},
    currentPage: {
      fontWeight: 800,
      color: theme.palette.primary.main
    },
    menuCtr: {
      position: 'relative',
      display: 'flex',
      flexGrow: 1,
      '& > div': {
        marginLeft: theme.spacing(3),
        '&:hover': {
          '& div': {
            display: 'block'
          }
        }
      },
      '& a': {
        '&:hover': {
          color: theme.palette.text.gray,
          textDecoration: 'none'
        }
      },
      '& > a:first-child': {
        marginLeft: theme.spacing(8)
      }
    },
    submenuCtr: {
      position: 'relative'
    },
    submenu: {
      position: 'absolute',
      left: -theme.spacing(3),
      display: 'none',
      backgroundColor: 'rgb(250,250,250)',
      minWidth: 230,
      '& div': {
        padding: `${theme.spacing(1.5)}px ${theme.spacing(3)}px`,
        paddingRight: theme.spacing(2.5)
      },
      '& a': {
        '&:hover': {
          color: 'inherit'
        }
      },
      '& div:hover': {
        backgroundColor: 'rgb(245,245,245)',
        display: 'block'
      }
    },
    mobileSubmenu: {
      minWidth: theme.spacing(13),
      display: 'none',
      '& div': {
        padding: theme.spacing(1)
      }
    },
    buttonsCtr: {
      '& > *': {
        marginRight: theme.spacing(4)
      },
      '& > *:last-child': {
        marginRight: 0
      },
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    barsIcon: {
      fontSize: 24
    },
    faTimes: {
      fontSize: 24,
      position: 'absolute',
      top: 15,
      right: 20
    },
    drawerPaper: {
      minHeight: 'calc(var(--vh, 1vh) * 100)',
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3)
    },
    drawerItem: {
      color: theme.palette.text.primary,
      padding: theme.spacing(3),
      textAlign: 'center',
      fontSize: '2rem',
      fontWeight: 700,
      '& a': {
        color: theme.palette.text.primary
      },
      '&:hover': {
        '& div': {
          display: 'block',
          paddingTop: theme.spacing(1)
        }
      }
    },
    subDrawerItem: {
      fontSize: '1.5rem',
      fontWeight: 700
    },
    contentCtr: {
      '& img': {
        width: theme.spacing(18)
      }
    },
    container: {
      alignItems: 'center',
      textAlign: 'center'
    },
    left: {
      textAlign: 'left',
      '& p, & a': {
        color: 'white',
        fontWeight: '600',
        [theme.breakpoints.down('sm')]: {
          fontSize: theme.spacing(1.5)
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: theme.spacing(2),
          textAlign: 'center'
        }
      }
    },
    right: {
      textAlign: 'right'
    },
    embedBtn: {
      display: 'block',
      top: 4,
      position: 'relative'
    },
    wrapper: {
      width: 'calc(100vw - 445px)',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      alignItems: 'center',
      textAlign: 'center',
      color: 'white',
      fontWeight: '600',
      margin: 'auto',
      [theme.breakpoints.down('md')]: {
        width: 'calc(100vw - 290px)'
      },
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100vw - 145px)',
        fontSize: theme.spacing(1.5),
        '& img': {
          width: theme.spacing(16)
        }
      },
      [theme.breakpoints.down('xs')]: {
        flexWrap: 'wrap',
        textAlign: 'center'
      }
    },
    logoAnchor: {
      marginTop: theme.spacing(0.75)
    }
  };
};
