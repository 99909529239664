// @flow
/* eslint-disable react/no-multi-comp */
import React from 'react';
import { classNames } from 'classnames';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import {Typography} from '@material-ui/core';


import { makeStyles } from '@material-ui/core/styles';
import SPButton from './SPButton';
import { styles } from './ContentfulHeroSectionCenteredWBackgroundImg.Style';

import type {
  contentfulRichText,
  contentfulImage
} from '../utils/types';

type Props = {
  backgroundImage: contentfulImage,
  title: string,
  subTitle: contentfulRichText
}

const ContentfulHeroSectionCenteredWBackgroundImg = (props: Props) => {
  console.log('[ContentfulHeroSectionCenteredWBackgroundImg] props: ', props);
  const {
    backgroundImage,
    title,
    subTitle
  } = props;
  // inject styles
  const useStyles = makeStyles(styles({
    backgroundImage: `url('${backgroundImage.file.url}')`
  }));
  const classes = useStyles();

  // custom of bold mark
  const renderSubTitleOptions = {
    renderNode: {
      // eslint-disable-next-line react/display-name
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Typography
          className={classes.subTitle}
          variant='body1'
        >{children}</Typography>
      )
    }
  };

  return (
    <Grid
      classes={{
        root: classes.container
      }}
      item
      xs={12}
    >
      <Grid
        className={classes.contentCtr}
        container
        direction='row'
      >
        <div className={classes.containerBackground}></div>
        {
          title
          ? <Grid
            item
            xs={12}
          >
            <Typography
              className={classes.title}
              variant='h1'
            >{ title }</Typography>
          </Grid>
          : null
        }
        
        {
          subTitle && subTitle.json
          ? <Grid
              item
              xs={12}
              className={classNames(
                classes.subTitleCtr,
                !title ? 'absent-title' : null
                )}
            >
              {documentToReactComponents(subTitle.json, renderSubTitleOptions)}
            </Grid>
          : null
        }
      </Grid>
    </Grid>
  );
};

export default ContentfulHeroSectionCenteredWBackgroundImg;