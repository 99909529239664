export const styles = theme => {
  return {
    container: {
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'center',
      padding: `${theme.spacing(15)}px 0`,
      textAlign: 'center'
    },
    image: {
      objectFit: 'contain',
      height: 75,
      [theme.breakpoints.down('sm')]: {
        height: 50
      },
      [theme.breakpoints.down('xs')]: {
        height: 35
      }
    },
    img: {
      [theme.breakpoints.down('xs')]: {
        maxWidth: '90vw'
      }
    }
  };
};
