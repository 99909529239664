// @flow
import React from 'react';
import { graphql } from 'gatsby';
import { window, document } from 'browser-monads';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';

import { makeStyles } from '@material-ui/core/styles';
import { styles } from './ResponsiveTextablePhone.Style';

type Props = {
  phoneNumber: string,
  mobileUrl: string
};

const useStyles = makeStyles(styles);
const ResponsiveTextablePhone = (props: Props) => {
  const classes = useStyles();
  const { phoneNumber, mobileUrl } = props;
  console.log('[ResponsiveTextablePhone] props: ', props);
  console.log(
    '/Android|webOS|iPhone|iPad|iPod|Opera Mini/i.test: ',
    /Android|webOS|iPhone|iPad|iPod|Opera Mini/i.test(
      window.navigator.userAgent
    )
  );
  return /Android|webOS|iPhone|iPad|iPod|Opera Mini/i.test(
    window.navigator.userAgent
  ) ? (
    <a href={mobileUrl}>{phoneNumber}</a>
  ) : (
    <span>{phoneNumber}</span>
  );
};

export default ResponsiveTextablePhone;

export const query = graphql`
  fragment ContentfulresponsiveTextablePhoneNumber on ContentfulresponsiveTextablePhoneNumber {
    phoneNumber
    mobileUrl
  }
`;
