// @flow
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';

import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';

import MenuIcon from '@material-ui/icons/Menu';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

import ContentfulLink from './ContentfulLink';
import SPButton from './SPButton';
import Logo from '../images/askneo-logo.png';

import { renderRichText } from '../utils/renderUtils';

import { makeStyles } from '@material-ui/core/styles';
import { styles } from './Header.Style';
const useStyles = makeStyles(styles);

import type { cpLink, cpButton, contentfulRichText } from '../utils/types';

import { window, document } from 'browser-monads';

type Props = {
  showTopBanner: boolean,
  topBanner: Object,
  col1: contentfulRichText,
  col2: contentfulRichText,
  col3: contentfulRichText,
  backgroundColor: String,
  mobilePopupLinks: Array<cpLink>,
  desktopCtaButtons: Array<cpButton>,
  desktopTopLinks: Array<cpLink>,
  ctaFunctions: Object
};

const sections = {
  ContentfulButton: SPButton
};

const Header = (props: Props) => {
  const classes = useStyles();
  const {
    desktopTopLinks,
    desktopCtaButtons,
    mobilePopupLinks,
    topBanner,
    showTopBanner,
    ctaFunctions
  } = props;
  // console.log('[ContentfulTopBanner] props: ', props);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  // console.log('[Header] props: ', props);

  const toggleDrawer = open => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setIsDrawerOpen(open);
  };
  const currentPage = window.location.pathname.replace('/', '').split('/')[0];
  // console.log('[Current page]:', currentPage);

  const renderBodyOptions = {
    renderNode: {
      // eslint-disable-next-line react/display-name
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Typography className={classes.body} variant='body1'>
          {children}
        </Typography>
      ),
      [BLOCKS.EMBEDDED_ASSET]: node => (
        <>
          {node && node.data.target.fields && node.data.target.fields.file ? (
            <img
              className={classNames(classes.embedTitleAsset)}
              src={node.data.target.fields.file.url}
              alt={node.data.target.fields.description}
            />
          ) : null}
        </>
      )
    }
  };

  return (
    <AppBar
      classes={{
        root: classes.root
      }}
      position='sticky'
    >
      {showTopBanner && topBanner ? (
        <Grid
          className={classes.container}
          style={{ backgroundColor: topBanner.backgroundColor }}
          container
        >
          <Grid className={classes.wrapper}>
            <Grid className={(classes.contentCtr, classes.left)} item xs={12}>
              {topBanner.col1 ? renderRichText(topBanner.col1.json) : null}
            </Grid>
            <Grid className={classes.contentCtr} item xs={12}>
              {topBanner.col2 ? renderRichText(topBanner.col2.json) : null}
            </Grid>
            <Grid className={(classes.contentCtr, classes.right)} item xs={12}>
              {/* {topBanner.col3 ? renderRichText(topBanner.col3.json) : null} */}
              {topBanner.col3 ? (
                <div>
                  <a
                    href='https://www.producthunt.com/posts/askneo-3-0-sms-automated-scripts?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-askneo-3-0-sms-automated-scripts'
                    target='_blank'
                  >
                    <img
                      src='https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=313974&theme=light'
                      alt='Askneo 3.0 • SMS Automated Scripts - Close sales faster and increase repeat customers | Product Hunt'
                      style={{ width: 250, height: 54 }}
                      width='250'
                      height='54'
                    />
                  </a>
                </div>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      ) : null}
      <Toolbar
        component='nav'
        classes={{
          root: classes.toolbarRoot
        }}
        disableGutters
      >
        <a href='/' className={classes.logoAnchor}>
          <img height='45' src={Logo} alt='logo' />
        </a>
        <Hidden xsDown>
          <div className={classes.menuCtr}>
            {desktopTopLinks
              ? desktopTopLinks.map((link, linkIndex) => (
                  <div
                    key={`top-link-${linkIndex}`}
                    className={classes.submenuCtr}
                  >
                    <a href={link.url || '#'}>
                      <Typography
                        className={
                          (classes.title,
                          link.label.toLowerCase() === currentPage
                            ? classes.currentPage
                            : link.label === 'Meet Neo' && !currentPage
                            ? classes.currentPage
                            : null)
                        }
                        variant='h6'
                        component='span'
                      >
                        {link.label}
                      </Typography>
                    </a>
                    {link.sublinks ? (
                      <Grid className={classes.submenu}>
                        {link.sublinks
                          ? link.sublinks.map((sublink, sublinkIdx) => (
                              <a
                                href={sublink.url}
                                key={`sublink-${sublinkIdx}`}
                              >
                                <Grid key={`sublink-${sublink.label}`}>
                                  <Typography variant='h6' component='span'>
                                    {sublink.label}
                                  </Typography>
                                </Grid>
                              </a>
                            ))
                          : null}
                      </Grid>
                    ) : null}
                  </div>
                ))
              : null}
          </div>
        </Hidden>
        <Hidden smDown>
          <div className={classes.buttonsCtr}>
            {desktopCtaButtons
              ? desktopCtaButtons.map((cta, ctaIndex) => {
                  const Section = sections[cta.__typename];
                  return cta && cta.__typename === 'ContentfulLink' ? (
                    <div key={`top-link-${ctaIndex}`}>
                      <ContentfulLink {...cta} ctaFunctions={ctaFunctions}>
                        <Typography
                          className={classes.title}
                          variant='h6'
                          component='span'
                        >
                          {cta.label}
                        </Typography>
                      </ContentfulLink>
                    </div>
                  ) : (
                    <Section
                      {...cta}
                      customClasses={{
                        button:
                          ctaIndex < desktopCtaButtons.length - 1
                            ? classes.ctaBtn
                            : null
                      }}
                      // color='secondary'
                      key={`top-cta-${ctaIndex}`}
                      // variant='outlined'
                    />
                  );
                })
              : null}
          </div>
        </Hidden>

        {/* Popup menu for mobile */}
        <Hidden mdUp className={classes.buttonsCtr}>
          <React.Fragment key={'top'}>
            <FontAwesomeIcon
              icon={faBars}
              onClick={toggleDrawer(true)}
              onKeyDown={toggleDrawer(false)}
              className={classes.barsIcon}
            />
            <Drawer
              anchor={'top'}
              onClose={toggleDrawer(false)}
              open={isDrawerOpen}
              classes={{
                paperAnchorTop: classes.drawerPaper
              }}
            >
              {mobilePopupLinks ? (
                <>
                  <FontAwesomeIcon
                    icon={faTimes}
                    onClick={toggleDrawer(false)}
                    className={classes.faTimes}
                  />
                  {mobilePopupLinks.map((link, linkIndex) => (
                    <div
                      className={classes.drawerItem}
                      key={`popup-link-${linkIndex}`}
                    >
                      <ContentfulLink {...link} ctaFunctions={ctaFunctions}>
                        {link.label}
                      </ContentfulLink>
                      {link.sublinks ? (
                        <Grid className={classes.mobileSubmenu}>
                          {link.sublinks
                            ? link.sublinks.map(sublink => (
                                <Grid key={`sublink-${sublink.label}`}>
                                  <ContentfulLink
                                    {...sublink}
                                    ctaFunctions={ctaFunctions}
                                  >
                                    <Typography
                                      variant='h6'
                                      component='span'
                                      className={classes.subDrawerItem}
                                    >
                                      {sublink.label}
                                    </Typography>
                                  </ContentfulLink>
                                </Grid>
                              ))
                            : null}
                        </Grid>
                      ) : null}
                    </div>
                  ))}
                </>
              ) : null}
            </Drawer>
          </React.Fragment>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
