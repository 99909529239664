/* eslint-disable react/no-multi-comp */
// @flow
import React from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { window, document } from 'browser-monads';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { styles } from './ContentfulBannerSection.Style';
import { Typography } from '@material-ui/core';

import { renderRichText } from '../utils/renderUtils';
import type { contentfulRichText, contentfulImage } from '../utils/types';

type Props = {
  image: contentfulImage,
  imageButtonUrl: string,
  content: contentfulRichText,
  mobileContent: contentfulRichText,
  columnDisplay: string,
  sizeSmall: boolean
};

const ContentfulBannerSection = (props: Props) => {
  const {
    image,
    content,
    mobileContent,
    sizeSmall,
    imageButtonUrl,
    columnDisplay,
    backgroundImage
  } = props;
  const useStyles = makeStyles(
    styles({
      container: {
        backgroundImage: backgroundImage
          ? `url('${backgroundImage.file.url}')`
          : 'none'
      }
    })
  );
  const classes = useStyles();
  console.log('[ContentfulBannerSection] props: ', props);

  const renderBodyOptions = {
    renderNode: {
      // eslint-disable-next-line react/display-name
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Typography className={classes.body} variant='h4' component='p'>
          {children}
        </Typography>
      ),
      [INLINES.EMBEDDED_ENTRY]: node => (
        <Typography variant='h4' component='h4' className={classes.body}>
          {node.data.target.fields.text}
        </Typography>
      )
    }
  };

  return (
    <Grid
      className={sizeSmall ? classes.smallContainer : classes.container}
      container
    >
      <Grid
        className={classNames(
          columnDisplay ? classes.columnCtr : null,
          sizeSmall ? classes.smallContentCtr : classes.contentCtr
        )}
        item
        xs={12}
      >
        {image && columnDisplay ? (
          <a target='_blank' className={classes.order2} href={imageButtonUrl}>
            <img
              alt={image.description}
              src={image.file.url}
              className={classes.colImage}
            />
          </a>
        ) : image ? (
          <img
            alt={image.description}
            src={image.file.url}
            className={classes.image}
          />
        ) : null}
        {content ? (
          <div>
            {documentToReactComponents(content.json, renderBodyOptions)}
          </div>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default ContentfulBannerSection;

export const query = graphql`
  fragment ContentfulBannerSection on ContentfulBannerSection {
    mobileContent {
      json
    }
    sizeSmall
    columnDisplay
    imageButtonUrl
    backgroundImage {
      file {
        url
      }
    }
    image {
      description
      file {
        url
        contentType
      }
    }
    content {
      json
    }
  }
`;
