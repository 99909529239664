export const styles = theme => {
  return {
    container: {
      // ...theme.squareplan.window.width,
      margin: '0 auto',
      flexGrow: 1,
      justifyContent: 'center',
      // padding: `${theme.spacing(15)}px 0 0 0`,
      background: 'transparent',
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(11),
      // height: 400,
      [theme.breakpoints.down('xs')]: {
        padding: `${theme.spacing(10)}px 0 ${theme.spacing(8)}px 0`
      }
    },
    carouselCtr: {
      width: '100%',
      display: 'block',
      overflowX: 'hidden'
      // height: 150,
      // maxHeight: 150
      // '& .slider-list': {
      //   minHeight: 'calc(100vw + 10rem)'
      // }
    },
    topContent: {
      textAlign: 'center',
      [theme.breakpoints.down('xs')]: {
        marginBottom: theme.spacing(2)
      }
    },
    slide: {
      height: 200,
      width: 200,
      display: 'block'
    },
    carouselItemCtr: {
      textAlign: 'center',
      '& *': {
        margin: '0 auto'
      }
    },
    sliderCtr: {
      [theme.breakpoints.down('xs')]: {
        overflowX: 'hidden'
        // marginRight: theme.spacing(2),
        // marginLeft: theme.spacing(2)
      }
    }
  };
};
