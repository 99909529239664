import * as R from 'ramda';

const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

// removes the locale nodes from inline embed models Contentful sends us
const recurringLocaleExtrator = (obj, extractKey) => {
  const moveNodeUp = (obj, key = 'fields') => {
    return typeof obj === 'object' && typeof obj[key] !== 'undefined'
      ? {
          ...R.omit([key], obj),
          ...obj[key]
        }
      : obj;
  };
  const removeChildrenKey = (x, key = 'fields') =>
    typeof x === 'object' && typeof x[key] !== 'undefined' ? x[key] : x;

  // loop through each node of the object
  // if children
  // console.log('[recurringLocaleExtrator] obj: ', obj);
  var keyList = R.keys(obj);
  // console.log('[recurringLocaleExtrator] keyList: ', keyList);
  var rootIdx = 0;
  let resObj = {};

  resObj = moveNodeUp(obj, extractKey);
  // console.log('[recurringLocaleExtrator] resObj: ', resObj);

  // then recur through children
  var idx = 0;
  var keyList = R.keys(resObj);
  while (idx < keyList.length) {
    var key = keyList[idx];
    // console.log('[recurringLocaleExtrator] key: ', key);
    let hasMoreNestedObjects = false;
    resObj[key] = removeChildrenKey(resObj[key], extractKey);
    if (typeof resObj[key] === 'object') {
      // console.log(
      //   `[recurringLocaleExtrator] recuring! Type: ${typeof resObj[
      //     key
      //   ]} - obj[${key}]: `,
      //   resObj[key]
      // );
      resObj[key] = recurringLocaleExtrator(resObj[key], extractKey);
    }
    idx += 1;
  }
  // console.log('[recurringLocaleExtrator] returning: ', resObj);
  return resObj;
};

const extractLanguage = (props, language = 'en-US') => {
  // console.log('[extractLanguage] props: ', props);

  const selectLanguage = x =>
    typeof x === 'object' && typeof x[language] !== 'undefined'
      ? x[language]
      : x;

  const res = recurringLocaleExtrator(props, language);
  // console.log('[extractLanguage] res: ', res);
  return res;
};

// removes the fields node from inline embed model media attachments from Contentful
const extractNestedFields = obj => {
  // console.log('[extractNestedFields] obj: ', obj);
  const res = recurringLocaleExtrator(obj, 'fields');
  // console.log('[extractNestedFields] res: ', res);
  return res;
};

export { capitalizeFirstLetter, extractLanguage, extractNestedFields };
