export const styles = theme => ({
  container: {
    backgroundColor: 'transparent',
    backgroundImage:
      'linear-gradient(180deg, rgba(234,234,234,0.23) 0%, #ffffff 100%)',
    paddingTop: theme.spacing(9),
    paddingBottom: theme.spacing(11)
  },
  contentCtr: {
    ...theme.squareplan.window.width,
    margin: '0 auto',
    '&.left': {
      textAlign: 'left'
    },
    '&.center': {
      textAlign: 'center'
    }
  },
  topContentCtr: {
    marginBottom: theme.spacing(2)
  },
  toolsSectionCtr: {
    display: 'flex',
    alignContent: 'space-around',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap'
  },
  bigLinksCtr: {
    paddingTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: `${theme.spacing(2)}px 0px`
    }
  },
  smallLinksCtr: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: `${theme.spacing(2)}px 0px`,
      paddingBottom: `${theme.spacing(2)}px 0px`
    }
  },
  toolCtr: {
    display: 'inline-block',
    margin: theme.spacing(1),
    borderRadius: theme.palette.border.radius,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      textDecoration: 'none'
    }
  },
  bigLink: {
    border: `2px solid ${theme.palette.primary.main}`,
    padding: `${theme.spacing(2.5)}px ${theme.spacing(4)}px`,
    fontSize: '1.1rem',
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  smallLink: {
    border: `1px solid ${theme.palette.primary.main}`,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    fontSize: '0.95rem',
    fontWeight: 500
  }
});
