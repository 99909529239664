/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
// @flow
import React from 'react';
import { graphql } from 'gatsby';
import YouTube from 'react-youtube';
import classNames from 'classnames';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

import { makeStyles } from '@material-ui/core/styles';
import { styles } from './Contentful2ColumnSection.Style';
import { Typography } from '@material-ui/core';

import type { spButton, contentfulRichText } from '../utils/types';
import SPButton from './SPButton';
import { renderRichText } from '../utils/renderUtils';

type Props = {
  description: contentfulRichText,
  c2csTitle: contentfulRichText,
  fullWidthTextTop: contentfulRichText,
  caseStudy: Object,
  authorPhoto: Object,
  showTextFirst: boolean,
  transparentBackground: boolean,
  c2csCta: spButton,
  theme: Object,
  ctaFunctions: Object
};

const TextCol = props => {
  const {
    classes,
    description,
    c2csTitle,
    caseStudy,
    showTextFirst,
    transparentBackground,
    c2csCta,
    theme,
    ctaFunctions
  } = props;

  return (
    <Grid
      className={classNames(
        transparentBackground ? classes.textCtrUseCase : classes.textCtr,
        theme ? classes[`background${theme.background}`] : null,
        showTextFirst ? classes.leftCol : classes.rightCol,
        theme && theme.textAlign === 'left'
          ? classes.alignLeft
          : theme && theme.textAlign === 'centerMobileOnly'
          ? classes.centerMobileOnly
          : classes.centerText,
        theme ? classes[`text${theme.text}`] : null,
        theme ? classes[`h1${theme.h1}`] : null,
        theme ? classes[`h2${theme.h2}`] : null,
        theme ? classes[`h3${theme.h3}`] : null,
        theme ? classes[`h4${theme.h4}`] : classes.h4Black,
        theme ? classes[`h5${theme.h4}`] : classes.h5Black
      )}
      item
      sm={
        theme && theme.textCol && theme.textCol.sm
          ? Number(theme.textCol.sm)
          : 6
      }
      xs={12}
    >
      <div>
        <div
          className={
            transparentBackground ? classes.titleCtrSmall : classes.titleCtr
          }
        >
          {c2csTitle && c2csTitle.json ? renderRichText(c2csTitle.json) : null}
        </div>
        <div
          className={classNames(
            classes.description,
            theme && theme.textAlign === 'left'
              ? classes.alignLeft
              : theme && theme.textAlign === 'center'
              ? classes.centerText
              : classes.centerMobileOnly
          )}
        >
          {description && description.json
            ? renderRichText(description.json)
            : null}
        </div>
        {c2csCta && theme && theme.ctaBtnTextColHidden ? (
          <Hidden {...theme.ctaBtnTextColHidden}>
            <div>
              <div className={classes.ctaCtr}>
                <SPButton
                  {...c2csCta}
                  ctaFunctions={ctaFunctions}
                  customClasses={{
                    button: classes.ctaBtn
                  }}
                />
              </div>
            </div>
          </Hidden>
        ) : c2csCta ? (
          <div>
            <div className={classes.ctaCtr}>
              <SPButton
                {...c2csCta}
                ctaFunctions={ctaFunctions}
                customClasses={{
                  button: classes.ctaBtn
                }}
              />
            </div>
          </div>
        ) : null}
      </div>
    </Grid>
  );
};

const ImgCol = props => {
  const {
    classes,
    image,
    column2EmbedVideo,
    showTextFirst,
    transparentBackground,
    theme
  } = props;
  // console.log(
  //   '[2colSection] ImgCol: image, column2EmbedVideo: ',
  //   image,
  //   column2EmbedVideo
  // );
  return (
    <Grid
      className={classNames(
        transparentBackground ? classes.imgCtrTop : classes.imgCtr,
        showTextFirst ? classes.rightCol : classes.leftCol
      )}
      item
      sm={
        theme && theme.imgCol && theme.imgCol.sm ? Number(theme.imgCol.sm) : 6
      }
      xs={12}
    >
      {column2EmbedVideo ? (
        <YouTube
          // opts={{
          //   width: 300
          // }}
          className={classes.videoClass}
          containerClassName={classes.videoCtr}
          videoId={column2EmbedVideo}
        />
      ) : null}
      {image ? (
        <LazyLoadImage
          alt={image.description}
          className={classNames(
            'leftImage',
            transparentBackground ? classes.imgCaseStudy : classes.img
          )}
          src={image.file.url}
        />
      ) : null}
    </Grid>
  );
};

const useStyles = makeStyles(styles);
const Contentful2ColumnSection = (props: Props) => {
  const classes = useStyles();
  const {
    theme,
    c2csTitle,
    description,
    showTextFirst,
    alignSection,
    caseStudy,
    transparentBackground,
    authorPhoto,
    fullWidthTextTop,
    c2csCta,
    ctaFunctions
  } = props;
  let caseStudyContent;
  if (caseStudy) {
    caseStudyContent =
      caseStudy.childrenContentfulTestimonialsCarouselContentJsonNode;
  }
  console.log('[Contentful2ColumnSection] props.theme: ', theme);

  return (
    <Grid
      className={classNames(
        !transparentBackground ? classes.grandientBackground : null,
        transparentBackground && theme && theme.background
          ? classes.container
          : null,
        theme ? classes[`h1${theme.h1}`] : null,
        theme ? classes[`h2${theme.h2}`] : null,
        theme ? classes[`h3${theme.h3}`] : null,
        theme ? classes[`text${theme.text}`] : null,
        theme ? classes[`background${theme.background}`] : null,
        theme ? classes[`img${theme.img}`] : null
      )}
      container
    >
      <Grid
        className={caseStudy ? classes.contentCtrSm : classes.contentCtr}
        container
        direction='row'
      >
        {fullWidthTextTop && fullWidthTextTop.json ? (
          <div
            className={classNames(
              alignSection === 'left' ? classes.alignLeft : classes.centerText,
              classes.fullWidthTextTop
            )}
          >
            {renderRichText(fullWidthTextTop.json)}
          </div>
        ) : null}
        {showTextFirst ? (
          <TextCol {...props} classes={classes} />
        ) : (
          <ImgCol {...props} classes={classes} />
        )}
        {showTextFirst ? (
          <ImgCol {...props} classes={classes} />
        ) : (
          <TextCol {...props} classes={classes} />
        )}
      </Grid>
      {caseStudy ? (
        <Grid className={classes.caseStudy}>
          <Grid className={classes.authorDetails}>
            <LazyLoadImage
              className={classes.avatar}
              src={authorPhoto.file.url}
            />
            <Typography className={classes.author}>
              {caseStudyContent[0].author}
            </Typography>
          </Grid>
          <div
            className={classes.content}
            dangerouslySetInnerHTML={{
              __html: `"${caseStudyContent[0].content}"`
            }}
          />
        </Grid>
      ) : null}
      {/* CTA Btn Footer */}
      {c2csCta && ctaFunctions && theme && theme.ctaBtnFooterHidden ? (
        <Hidden {...theme.ctaBtnFooterHidden}>
          <Grid
            item
            xs={12}
            className={classNames(
              classes.footerBtnCtr,
              theme.alignFooterCta === 'center' ? classes.centerText : null
            )}
          >
            <SPButton
              {...c2csCta}
              ctaFunctions={ctaFunctions}
              customClasses={{
                button: classes.ctaBtn
              }}
            />
          </Grid>
        </Hidden>
      ) : null}
    </Grid>
  );
};

export default Contentful2ColumnSection;

export const query = graphql`
  fragment Contentful2ColumnSection on Contentful2ColumnSection {
    theme {
      background
      h1
      h2
      h3
      h4
      h5
      text
      textAlign
      img
      textCol {
        sm
      }
      imgCol {
        sm
      }
      ctaBtnTextColHidden {
        xsDown
        xsUp
      }
      ctaBtnFooterHidden {
        smUp
        xsUp
      }
      alignFooterCta
    }
    fullWidthTextTop {
      json
    }
    alignSection
    showTextFirst
    authorPhoto {
      description
      file {
        url
        contentType
      }
    }
    caseStudy {
      childrenContentfulTestimonialsCarouselContentJsonNode {
        content
        author
      }
    }
    transparentBackground
    c2csTitle: title {
      json
    }
    description {
      json
    }
    image {
      description
      file {
        url
        contentType
      }
    }
    c2csCta: cta {
      ...ContentfulButton
    }
  }
`;
