// @flow
import React from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import ReactMarkdown from 'react-markdown';
import Button from '@material-ui/core/Button';

import { makeStyles } from '@material-ui/core/styles';
import { styles } from './SeoHeading4Text.Style';
import { Typography } from '@material-ui/core';

type Props = {
  text: string
};

const useStyles = makeStyles(styles);
const SeoHeading4Text = (props: Props) => {
  const classes = useStyles();
  console.log('[SeoHeading4Text] props: ', props);
  const { text } = props;

  const renderers = {
    heading: ({ level, children }) => {
      // console.log('value: ', level, children);
      return (
        <Typography component='h4' variant={`h${level}`}>
          {children}
        </Typography>
      );
    }
  };
  return <ReactMarkdown renderers={renderers}>{text}</ReactMarkdown>;
};

export default SeoHeading4Text;

export const query = graphql`
  fragment ContentfulSeoHeading4Text on ContentfulSeoHeading4Text {
    text {
      json
    }
  }
`;
