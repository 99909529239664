export const styles = theme => {
  return {
    container: {
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'center',
      padding: `${theme.spacing(15)}px 0`
    },
    wrapper: {
      minHeight: theme.spacing(25),
      borderRadius: theme.spacing(5),
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center'
    },
    content: {
      fontWeight: 600,
      display: 'block'
    },
    author: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(4)
    },
    carouselContainer: {
      margin: 'auto',
      width: '65%',
      margin: 'auto',
      padding: theme.spacing(9),
      background: 'rgba(243, 241, 239, 1)',
      borderRadius: theme.spacing(8),
      [theme.breakpoints.down('lg')]: {
        height: theme.spacing(35)
      },
      [theme.breakpoints.down('sm')]: {
        height: theme.spacing(40.6)
      },
      [theme.breakpoints.down('xs')]: {
        width: '90%',
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5)
      }
    },
    quoteMark: {
      display: 'inline',
      fontWeight: 900,
      fontSize: '6rem'
    }
  };
};
