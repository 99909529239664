/* eslint-disable react/display-name */
// @flow
/* eslint-disable react/no-multi-comp */
import React from 'react';
import classNames from 'classnames';
import { renderRichText } from '../utils/renderUtils';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { Typography } from '@material-ui/core';

import SPButton from './SPButton';
import { makeStyles } from '@material-ui/core/styles';
import { styles } from './ContentfulBackgroundImageSection.Style';

import type {
  spButton,
  contentfulRichText,
  contentfulImage
} from '../utils/types';

type Props = {
  backgroundImage: contentfulImage,
  backgroundSize: 'cover' | 'contain',
  color: 'primary' | 'secondary',
  cbisTitle: contentfulRichText,
  cbisTitlePosition: string,
  cbisBody: contentfulRichText,
  cbisBodyPosition: string,
  cbisCta: spButton,
  cbisCtaPosition: string,
  ctaCaption: string,
  ctaFunctions: Object
};

const ContentfulBackgroundImageSection = (props: Props) => {
  console.log('[ContentfulBackgroundImageSection] props: ', props);
  const {
    backgroundImage,
    backgroundSize,
    color,
    cbisTitle,
    cbisTitlePosition,
    cbisBody,
    cbisBodyPosition,
    cbisCta,
    ctaCaption,
    cbisCtaPosition,
    ctaFunctions
  } = props;
  // inject styles
  const useStyles = makeStyles(
    styles({
      backgroundImage: `url('${backgroundImage.file.url}')`,
      backgroundSize,
      color
    })
  );
  const classes = useStyles();

  return (
    <Grid
      classes={{
        root: classes.container
      }}
      item
      xs={12}
    >
      <Grid
        className={classNames(
          classes.contentCtr,
          // !cbisTitle ? 'absent-title' : null,
          !cbisTitle && !cbisCta ? classes.absentTitleNCta : null
        )}
        container
        direction='column'
      >
        <div className={classes.containerBackground}></div>
        {cbisTitle ? (
          <Grid
            item
            xs={12}
            classes={{
              root: classNames(
                classes[`position${cbisTitlePosition}`],
                classes.titleCtr
              )
            }}
          >
            {cbisTitle ? renderRichText(cbisTitle.json) : null}
          </Grid>
        ) : null}
        {cbisBody ? (
          <Grid
            item
            xs={12}
            classes={{
              root: classNames(classes[`position${cbisBodyPosition}`])
            }}
          >
            {cbisBody ? renderRichText(cbisBody.json) : null}
          </Grid>
        ) : null}

        {cbisCta ? (
          <Grid
            item
            xs={12}
            classes={{
              root: classNames(classes[`position${cbisCtaPosition}`])
            }}
          >
            <SPButton
              {...cbisCta}
              customClasses={{
                button: classNames(classes.ctaBtn)
              }}
              ctaFunctions={ctaFunctions}
            />
            <Typography variant='body1' className={classes.ctaCaption}>
              {ctaCaption}
            </Typography>
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default ContentfulBackgroundImageSection;
