/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
// @flow
import React from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import Grid from '@material-ui/core/Grid';

import { makeStyles } from '@material-ui/core/styles';
import { styles } from './ContentfulAccordion.Style';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core';
import type { contentfulRichText } from '../utils/types';

type Props = {
  sectionHeader: string,
  heading: contentfulRichText,
  content: contentfulRichText,
  anchor: string,
  finalAccordion: boolean,
  forceExpand: Boolean,
  value: string
};

const useStyles = makeStyles(styles);
const ContentfulAccordion = (props: Props) => {
  const classes = useStyles();
  const {
    sectionHeader,
    heading,
    content,
    anchor,
    value,
    finalAccordion,
    forceExpand
  } = props;

  const renderOptions = {
    renderNode: {
      // eslint-disable-next-line react/display-name
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Typography>{children}</Typography>
      )
    }
  };

  return (
    <Grid className={classNames(classes.container)} item sm={6} xs={12}>
      {sectionHeader ? (
        <div>
          <h2 className={classes.sectionHeader}>{sectionHeader}</h2>
          <span className={classes.anchorCtr} id={anchor} />
        </div>
      ) : null}
      <Accordion
        className={finalAccordion ? classes.finalCtr : classes.accordionCtr}
        defaultExpanded={forceExpand}
      >
        <AccordionSummary
          className={classes.title}
          expandIcon={
            <svg
              height='18'
              viewBox='0 0 18 18'
              width='18'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path d='M12.44 6.44L9 9.88 5.56 6.44 4.5 7.5 9 12l4.5-4.5z' />
            </svg>
          }
        >
          {documentToReactComponents(heading.json, renderOptions)}
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          {documentToReactComponents(content.json, renderOptions)}
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default ContentfulAccordion;

export const query = graphql`
  fragment ContentfulAccordion on ContentfulAccordion {
    sectionHeader
    heading {
      json
    }
    content {
      json
    }
    anchor
    finalAccordion
    forceExpand
  }
`;
