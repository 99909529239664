//@flow

import React from 'react';
import { graphql } from 'gatsby';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { styles } from './ContentfulSixIconBoxSection.Style';
import theme from '../theme';
import { renderRichText } from '../utils/renderUtils';

import type { contentfulImage, contentfulRichText } from '../utils/types';

type Props = {
  featuresText: Object,
  icons: Object,
  contentTop: contentfulRichText,
  image: contentfulImage
};

const ContentfulSixIconBoxSection = (props: Props) => {
  console.log('[ContentfulSixIconBoxSection]', props);
  const { featuresText, icons, contentTop, image } = props;
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  return (
    <Grid
      classes={{
        root: classes.container
      }}
      item
      xs={12}
    >
      <Grid className={classes.contentCtr}>
        {contentTop ? renderRichText(contentTop.json) : null}
        {/* {title ? (
          <Typography className={classes.title} variant='h1'>
            {title}
          </Typography>
        ) : null} */}
        {image ? (
          <Typography>
            <img
              alt={image.description}
              className={classes.imgCtr}
              src={image.file.url}
            />
          </Typography>
        ) : null}
        {/* {subtitle ? (
          <Typography className={classes.subtitle} variant='h3'>
            {subtitle}
          </Typography>
        ) : null} */}
        {featuresText.map((featureText, i) => (
          <Grid key={i}>
            <Grid className={classes.iconBox}>
              <img alt={icons[i].description} src={icons[i].file.url} />
              <Typography className={classes.featureText}>
                {featureText.text}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default ContentfulSixIconBoxSection;

export const query = graphql`
  fragment ContentfulSixIconBoxSection on ContentfulSixIconBoxSection {
    contentTop {
      json
    }
    featuresText: features {
      text
    }
    image {
      description
      file {
        url
        contentType
      }
    }
    icons {
      description
      file {
        url
        contentType
      }
    }
  }
`;
